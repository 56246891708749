<ion-header>

  <ion-toolbar>
    <ion-title class="ion-text-wrap"><span [translate]="title"></span></ion-title>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">
  <ion-radio-group [(ngModel)]="selectedRecipient">
    <ion-label><span translate="selectRecipient.instruction"></span></ion-label>
    <ion-item *ngFor="let recipient of recipients">
      <ion-label class="ion-text-wrap">{{ recipient.identity }}
        <span
          *ngIf="!recipient.relationMore">: {{ 'transition.relationWithClient.relations.' + recipient.relation | translate }}</span>
        <span *ngIf="recipient.relationMore">: {{ recipient.relationMore }}</span>
      </ion-label>
      <ion-radio [value]="recipient" slot="end"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-content>

<ion-footer class="actions-footer">
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row>
        <ion-col
          size="6"
          class="transitions-buttons-col">
          <ion-button
            expand="full"
            size="large"
            class="transition-button"
            fill="solid"
            color="light"
            (click)="cancel()">
            <span translate="actions.cancel" class="transition-button"></span>
          </ion-button>
        </ion-col>
        <ion-col
          size="6"
          class="transitions-buttons-col">
          <ion-button
            expand="full"
            size="large"
            fill="solid"
            class="transition-button"
            color="success"
            [disabled]="!selectedRecipient"
            (click)="activate()"><span
            translate="actions.ok"></span></ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
</ion-footer>
