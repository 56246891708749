import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {LoadingController, Platform, ToastController} from '@ionic/angular';
import {UserSettings} from '@models/settings/settings.model';
import {TranslateService} from '@ngx-translate/core';
import {IonicDeployStatus} from '@services/ionic-deploy/ionic-deploy-status';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {UserService} from '@services/user/user.service';
import {Deploy} from 'cordova-plugin-ionic/dist/ngx';
import {BehaviorSubject, Observable} from 'rxjs';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

const CONFIG = {
  CHANNEL: 'IONIC_DEPLOY_CHANNEL'
};

@Injectable({
  providedIn: 'root'
})
export class IonicDeploy {

  private runningDeploy = false;
  private isCordovaEnv = false;
  private pluginWasInitialized = false;

  private downloadingPrefix = 'Téléchargement';
  private extractingPrefix = 'Extraction';

  private deployStatusBehaviorSubject: BehaviorSubject<IonicDeployStatus> = new BehaviorSubject(new IonicDeployStatus());
  public observableStatus: Observable<IonicDeployStatus> = this.deployStatusBehaviorSubject.asObservable();

  private userSettings: UserSettings;

  private _channel = 'Production';

  private channels: Array<string> = [
    'yellow',
    'purple',
    'green',
    'blue'
  ];

  constructor(private mobileContextService: MobileContextService,
              private platform: Platform,
              private toastCtrl: ToastController,
              private translate: TranslateService,
              private userService: UserService,
              private log: LogService,
              private loadingCtrl: LoadingController,
              private deploy: Deploy,
              private swUpdate: SwUpdate,
              private capacitorPlugins: CapacitorPlugins
  ) {
    this.platform.ready()
      .then(() => {
        this.check();
        setInterval(() => {
          this.log.trace('Checking for new available version from ionic servers');
          this.check();
        }, 15 * 60 * 1000);
      });
    // Listen to profile changes to adjust service channel
    this.mobileContextService.userSettingsObservable
      .subscribe((userSettings: UserSettings) => {
        this.userSettings = userSettings;
        if (!userSettings
          || (!this.userSettings.channel || !this._channel)
          || (userSettings.channel !== this._channel)
        ) {
          this.findMostSuitableChannel().catch(error => {
            this.log.error('Unable to find most suitable channel : ', error);
          });
        }

      });

    this.checkServiceWorkerUpdate();
  }

  findMostSuitableChannel(): Promise<any> {
    this.log.trace('Finding the most suitable channel for this device and user.');
    return this.capacitorPlugins.getPreferencesPlugin().get({key: CONFIG.CHANNEL})
      .then(result => {
        this.log.info('Getting previous channel for device : ', result.value);
        return result.value;
      }, error => {
        this.log.error('Unable to get previous channel for device : ', error);
        return null;
      })
      .then(channel => {
        let resolvedChannel;
        if (this.userSettings && this.userSettings.channel) {
          this.log.info('The current user has a channel enforced : ' + this.userSettings.channel);
          resolvedChannel = this.userSettings.channel;
        } else if (channel) {
          this.log.info('The device has a channel enforced : ' + channel);
          resolvedChannel = channel;
        } else {
          const randomChannel = this.channels[Math.floor(Math.random() * this.channels.length)];
          this.log.debug('Getting a new random channel for device : ', randomChannel);
          resolvedChannel = randomChannel;
        }
        this.capacitorPlugins.getPreferencesPlugin().set({key: CONFIG.CHANNEL, value: resolvedChannel.toString()})
          .then(success => {
            this.log.info('New ionic deploy channel for device persisted to storage : ', resolvedChannel, success);
          }, error => {
            this.log.error('Unable to persist new ionic deploy channel for device : ', resolvedChannel);
          });
        this.log.info('The resolved Ionic deploy channel to use is : ' + resolvedChannel);
        return resolvedChannel;
      })
      .then(channel => {
        if (channel !== this._channel) {
          this.deployStatusBehaviorSubject.next(new IonicDeployStatus(false));
          this.userService.setCurrentIonicChannel(channel);
          this.pluginWasInitialized = false;
          this._channel = channel;
          this.configure();
        }
        return channel;
      });
  }


  get channel(): string {
    return this._channel;
  }

  removeChannelFromStorage() {
    return this.capacitorPlugins.getPreferencesPlugin().remove({key: CONFIG.CHANNEL});
  }

  resetLocalChannel() {
    this.log.trace('Resetting ionic deploy local channel.');
    return this.removeChannelFromStorage()
      .then(() => this.findMostSuitableChannel());
  }

  forceChannelForUser(channel: string) {
    this.log.info('Ionic channel is being enforced for the current user : ', channel);
    this.pluginWasInitialized = true;
    this.userService.setIonicChannel(channel);
    this._channel = channel;
    this.configure();
  }

  configure() {
    this.translate.get(['deploy.downloadPrefix', 'deploy.extractPrefix'])
      .subscribe(values => {
        let results = Object.keys(values).map(key => values[key]);
        this.downloadingPrefix = results[0];
        this.extractingPrefix = results[1];
      });

    this.log.info('Initializing Ionic deploy plugin with channel : ' + this._channel);

    const config = {
      appId: 'af33d998',
      channel: this._channel
    };

    return this.deploy.configure(config)
      .then(() => {
        this.pluginWasInitialized = true;
      });
  }

  check() {
    if (this.runningDeploy) {
      return Promise.resolve('Ionic deploy update in progress.');
    }

    this.userService.setCurrentIonicChannel(this._channel);
    return new Promise((resolve, reject) => {
      this.deploy.checkForUpdate().then(result => {
        if (result) {
          this.log.info(`Ionic deploy check for channel ${this._channel}, available: ${result.available}, snapshot: ${result.snapshot}`);
          if (result.available) {
            this.deployStatusBehaviorSubject.next(new IonicDeployStatus(true));
          } else {
            this.deployStatusBehaviorSubject.next(new IonicDeployStatus(false));
          }
        }
        resolve((result));
      }, err => reject(err));
    });
  }

  download(onProgress) {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.downloadUpdate(progress =>
        onProgress(progress)
      );
    }
  }

  extract(onProgress) {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.extractUpdate(progress =>
        onProgress(progress)
      );
    }
  }

  load() {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.reloadApp();
    }
  }

  info() {
    if (this.onlyIfPluginInitialized()) {
      if (this.runningDeploy) {
        return Promise.reject('Ionic deploy update in progress.');
      }

      return this.deploy.checkForUpdate();
    }
  }

  getCurrentVersion() {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.getCurrentVersion();
    }
  }

  getVersions() {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.getAvailableVersions();
    }
  }

  deleteVersion(version) {
    if (this.onlyIfPluginInitialized()) {
      return this.deploy.deleteVersionById(version.versionId);
    }
  }

  async installNewVersion(): Promise<any> {
    if (this.runningDeploy) {
      return Promise.resolve();
    }

    this.log.info('Installing latest ionic deploy version (if available) for channel ' + this._channel);

    let toast = await this.toastCtrl.create({
      message: this.downloadingPrefix + ' ... 0%',
      position: 'bottom',
      cssClass: 'processing-toast'
    });
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      // https://github.com/ionic-team/ionic/blob/5f3c7cd755d2a9c0b6cabf6c0900168421c1e082/angular/BREAKING.md#loading
      // dismissOnPageChange: true
    });

    return this.check()
      .then((snapshotAvailable: { available: boolean }) => {
        this.log.info('Ionic deploy : checking before installing new snapshot : ' + snapshotAvailable + ' for channel ' + this._channel);
        if (snapshotAvailable.available) {
          this.runningDeploy = true;
          toast.present();
          return this.download(
            async percent => {
              toast = await this.toastCtrl.create({
                message: this.downloadingPrefix + ' ... ' + percent + '%',
                position: 'bottom',
                cssClass: 'processing-toast'
              });
            });
        } else {
          return Promise.reject('Ionic deploy : no snapshot available for channel ' + this._channel + ', aborting installation.');
        }
      })
      .then(() => {
          return this.extract(async percent => {
            toast = await this.toastCtrl.create({
              message: this.extractingPrefix + ' ... ' + percent + '%',
              position: 'bottom',
              cssClass: 'processing-toast'
            });
          });
        }
      )
      .then(() => {
        this.log.info('Ionic deploy : Ionic install, reloading the app');
        loading.present();
        return this.load();
      })
      .then(() => {
        this.log.info('Ionic deploy : Ionic install, done.');
        toast.dismiss();
        loading.dismiss();
        this.runningDeploy = false;
      })
      .catch((error) => {
        this.log.error('Ionic deploy : deploy process catching with a message', error);
        this.runningDeploy = false;
        toast.dismiss();
        loading.dismiss();
      });
  }

  private onlyIfPluginInitialized() {
    if (!this.pluginWasInitialized) {
      this.log.error('Ionic deploy : IonicDeploy was not initialized, you should call init method!');
      return false;
    } else {
      return true;
    }
  }

  private checkServiceWorkerUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((next) => {
        if (next) {
          this.deployStatusBehaviorSubject.next(new IonicDeployStatus(true));
        }
      });
    }
  }
}
