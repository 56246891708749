<ion-header>
  <ion-toolbar>
    <ion-title>Page to style UI and test features</ion-title>
    <ion-buttons slot="end" (click)="close()">
      <ion-button outline>
        {{ 'actions.close' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="gray-bg">

  <ion-card>
    <ion-card-header>
      Modal dialogs
    </ion-card-header>
    <ion-card-content>
      <ion-button block color="danger" (click)="showErrorDialog()">Show error dialog</ion-button>
      <ion-button block color="success" (click)="showSuccessDialog()">Show success dialog</ion-button>
      <ion-button block color="success" (click)="showConfirmationDialogOnMapViewFailed('test error message')">Show Confirmation Dialog On Map View Failed</ion-button>
      <ion-button block color="success" (click)="showConfirmationPutOrderBackToStoreDialog()">Show Put Order Back Confirmation Dialog</ion-button>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Emulate Online/Offline Mode
    </ion-card-header>
    <ion-card-content>
      <ion-button expand="block" color="warning" (click)="goOffline()">Go Offline</ion-button>
      <ion-button expand="block" color="success" (click)="goOnline()">Go Online</ion-button>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Emulate Error (test Sentry)
    </ion-card-header>
    <ion-card-content>
      <ion-button expand="block" color="danger" (click)="triggerError()">Trigger Error</ion-button>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Emulate IndexedDB Error
    </ion-card-header>
    <ion-card-content>
      <ion-text color="danger">
        To emulate IndexedDB error, please tap the below button, then periodially send the app to background and return it to foreground multiple times.
        You may also need to run in parallel other applications which consume considerable resouces (memory, CPU, etc.).
      </ion-text>
      <ion-button expand="block" color="warning" (click)="executeDBOperations()">Execute DB operations</ion-button>
      <!-- [disabled]="executeDBOperationsInProgress === true" -->
    </ion-card-content>
  </ion-card>


</ion-content>

