<ion-header>
  <ion-toolbar>
    <ion-title><span class="ion-text-wrap" translate="pages.location-disclosure.title"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <img src="../../../assets/images/geolocation.png"/>

    <ion-item lines="none" class="message1">
      <span translate="pages.location-disclosure.message1" class="message"></span>
    </ion-item>
    <ion-item lines="none" class="message1">
      <span translate="pages.location-disclosure.message2" class="message"></span>
    </ion-item>
    <ion-item lines="none" class="privacy-policy">
      <div class="message">
        <span translate="policies.privacyPolicy"></span>&nbsp;:
        <a [href]="policies.privacyPolicyUrl" target="_blank">{{ policies.privacyPolicyUrl }}</a>
      </div>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-checkbox [legacy]="true" class="footer-checkbox" [(ngModel)]="stopShowing"></ion-checkbox>
    <ion-label>
      <span translate="pages.location-disclosure.stop-showing"></span>
    </ion-label>
    <ion-button slot="end" (click)="close()"><span translate="actions.close"></span></ion-button>
  </ion-toolbar>
</ion-footer>
