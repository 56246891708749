import {Input} from '@angular/core';
import {Component} from '@angular/core';
import {Bag} from '@models/business/bag.model';

@Component({
  selector: 'mobilus-bag',
  templateUrl: './bag.component.html',
  styleUrls: ['./bag.component.scss'],
})
export class BagComponent {

  @Input() bag: Bag;

  constructor() {
  }

}

