import {Renderer2} from '@angular/core';
import {ElementRef} from '@angular/core';
import {ViewChild} from '@angular/core';
import {Input} from '@angular/core';
import {AfterViewInit} from '@angular/core';
import {Component} from '@angular/core';

@Component({
  selector: 'mobilus-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
})
export class AccordionListComponent implements AfterViewInit {

  @Input() headerColor = 'darkgrey';
  @Input() textColor = '#FFF';
  @Input() contentColor = '#F9F9F9';
  @Input() title: string;
  @Input() hasMargin = true;
  @Input() expanded: boolean;

  @ViewChild('accordionContent', {static: true}) elementView: ElementRef;

  viewHeight: number;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.viewHeight = this.elementView.nativeElement.offsetHeight;

    if (!this.expanded) {
      this.renderer.setStyle(this.elementView.nativeElement, 'height', 0 + 'px');
    }
  }

  toggleAccordion() {
    this.expanded = !this.expanded;
    const newHeight = this.expanded ? '100%' : '0px';
    this.renderer.setStyle(this.elementView.nativeElement, 'height', newHeight);
  }
}
