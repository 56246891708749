import { InjectionToken } from '@angular/core';

export interface AppSettings {
    CONNECTIVITY_CHECK_INTERVAL: number; // ping interval in seconds
    CONNECTIVITY_CHECK_PATH: string; // ping path
    GOOGLE_API_KEY: string;
    TIMEOUT: number;
}
export const COURIER_APP_SETTINGS: AppSettings = {
    CONNECTIVITY_CHECK_PATH: '/heartbeat/ping',
    CONNECTIVITY_CHECK_INTERVAL: 5,
    GOOGLE_API_KEY: 'AIzaSyB3sjnFOcteJD6HCwGcagvHuDHLnm8wxC8',
    TIMEOUT: 15000
};
export const APP_SETTINGS = new InjectionToken<AppSettings>('app.settings', {
    providedIn: 'root',
    factory: () => COURIER_APP_SETTINGS
});
