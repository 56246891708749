import { Component, OnDestroy, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// COMPONENTS
import { TransitionsDebugComponent } from '@app/components/transitions-debug/transitions-debug.component';

// SERVICES
import { AwaitingTransitionStoreService } from '@services/awaiting-transition/awaiting-transition-store.service';

@Component({
  selector: 'mobilus-awaiting-transition-indicator',
  templateUrl: './awaiting-transition-indicator.component.html',
  styleUrls: ['./awaiting-transition-indicator.component.scss'],
})
export class AwaitingTransitionIndicatorComponent implements OnDestroy {

  public count = 0;

  public componentDestroyed: Subject<void> = new Subject<void>();

  constructor(private awaitingTransitionStoreService: AwaitingTransitionStoreService,
    private modalController: ModalController,
    private ngZone: NgZone
  ) {
    this.awaitingTransitionStoreService.countObservable
      .pipe(
        takeUntil(this.componentDestroyed),
      )
      .subscribe(count => {
        this.ngZone.run(() => {
          this.count = count;
        });
      });
  }

  public async openTransitionsDebug() {
    const transitionsDebugComponentModal = await this.modalController.create({
      component: TransitionsDebugComponent,
      backdropDismiss: false
    });
    await transitionsDebugComponentModal.present();
  }


  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

}
