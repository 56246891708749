// QUESTION: do we want to (a) group the imports (THIRD PARTY LIBS, MODELS, PROVIDERS etc) and (b) order them alphabetically?

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MultipleOrderTransitionPage } from '@app/pages/multiple-order-transition/multiple-order-transition.page';
import { TransitionDetailPage } from '@app/pages/transition-detail/transition-detail.page';
import { TransitionParameters } from '@app/pages/transition-detail/transition-parameters.model';
import { DistancePipe } from '@app/pipes/distance/distance.pipe';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Hauler } from '@models/business/hauler.model';
import { Order } from '@models/business/order.model';
import { Position } from '@models/business/position.model';
import { Picture, TransitionData } from '@models/business/transition-data.model';
import { ConnectionStatus } from '@models/information/connection-status.model';
import { DeviceInfo } from '@models/information/device-info.model';
import { OrderView } from '@models/order-helper/order-view.model';
import { UserSettings } from '@models/settings/settings.model';
import { TransitionRequest } from '@models/workflow/transition-request.model';
import { Transition } from '@models/workflow/transition.model';
import { TransitionRequestLog } from '@models/workflow/transition-request-log.model';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '@services/auth/token.service';
import { AwaitingTransitionStoreService } from '@services/awaiting-transition/awaiting-transition-store.service';
import { BackgroundGeolocationService } from '@services/background-geolocation/background-geolocation.service';
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { LogService } from '@services/log/log.service';
import { MobileContextService } from '@services/mobile-configuration-service/mobile-context.service';
import { OrderStoreService } from '@services/order-store/order-store.service';
import { OrderSyncService } from '@services/order-sync/order-sync.service';
import { ReportingService } from '@services/reporting-service/reporting.service';
import { HttpTransitionRequest } from '@services/transition/model/http-transition-request.model';
import { HttpTransitionResponse } from '@services/transition/model/http-transition-response.model';
import { PreSignedURLResponse } from '@services/transition/model/presigned-url-response.model';
import { WorkflowService } from '@services/workflow/workflow.service';
import { classToClass, classToPlain, plainToClass } from '@utils/json-converter/json-converter';
import * as geolib from 'geolib';
import * as moment from 'moment';
import { combineLatest, concat, EMPTY, firstValueFrom, from, Observable, of, Subject, Subscriber, Subscription } from 'rxjs';
import { bufferTime, concatMap, delay, filter, map, tap, toArray } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { HttpUtils } from '@services/utils/http-utils';
import { DialogsService } from '@services/dialogs/dialogs';
import { CommonUtils } from '@services/utils/common-utils';
import { AppError } from '@models/base/base';
import { filterByPromise } from 'filter-async-rxjs-pipe';

const CONFIG = {
  PATH_TRANSITION: '/mobile/v2/transition',
  PATH_TRANSITIONS: '/mobile/v2/transitions',
  PATH_PRESIGNED_URL: '/file/presignedPickupPrescritionPictureUpload',
  EDIT_PAYMENT_EVENT: 'PAYMENT_UPDATE',
  MAX_TRANSITION_RETRY: 20
};

@Injectable({
  providedIn: 'root'
})
export class TransitionService {
  transitionsSequence = 100;
  private _retryingTransitions = false;
  public get retryingTransitions() {
    return this._retryingTransitions;
  }
  public set retryingTransitions(value) {
    this.ngZone.run(() => {
      this._retryingTransitions = value;
    });
  }
  private liveChangeSubscription: Subscription = null;
  private hauler: Hauler;
  private connectionStatus: ConnectionStatus;
  private isAndroid = false;
  private isIos = false;
  settings: UserSettings;
  metersConverters = 1000;
  private pendingTransitionsIDs: Array<string> = [];

  private transitionErrorsSink: Subject<{ error: any, transitionRequest: TransitionRequest }> = new Subject();
  // NOTE: If a transition failed because of connections issues, display a popup once (for all transitions) every 15 minutes (to avoid spamming the user)
  private readonly TRANSITION_TIMEOUT_ERROR_THROTTLE = 15 * 60 * 1000; // 15 mins
  private lastTransitionTimeoutErrorTime: moment.Moment = null;
  private failedTransitionErrorDialog: HTMLIonAlertElement = null;

  constructor(private workflowService: WorkflowService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private orderStore: OrderStoreService,
    private orderSync: OrderSyncService,
    private endpointService: EndpointService,
    private awaitingTransitionStoreService: AwaitingTransitionStoreService,
    private reportingService: ReportingService,
    private deviceInfo: DeviceInfo,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private log: LogService,
    private connectionStatusService: ConnectionStatusService,
    private mobileContextService: MobileContextService,
    private platform: Platform,
    private backgroundGeolocationService: BackgroundGeolocationService,
    private tokenService: TokenService,
    public distancePipe: DistancePipe,
    private httpUtils: HttpUtils,
    private dialogsService: DialogsService,
    private commonUtils: CommonUtils,
    private ngZone: NgZone
  ) {
    this.platform.ready()
      .then(() => {
        this.isAndroid = this.platform.is('android');
        this.isIos = this.platform.is('ios');
      });

    this.mobileContextService.haulerObservable.subscribe(hauler => {
      this.hauler = hauler;
    });

    this.connectionStatusService.connectionStatusSubscription.subscribe(status => {
      this.connectionStatus = status;
    });

    this.awaitingTransitionStoreService.changeObservable
      .subscribe((value) => {
        if (value === 'onReset') {
          if (this.liveChangeSubscription) {
            this.liveChangeSubscription.unsubscribe();
          }
        } else {
          this.awaitingTransitionStoreService.ready()
            .then(() => {
              this.liveChangeSubscription = this.awaitingTransitionStoreService
                .getLiveChanges()
                .pipe(
                  filter(pendingTransitionChange => !pendingTransitionChange.deleted),
                  concatMap(pendingTransitionChange => of(pendingTransitionChange.doc).pipe(delay(250))),
                  bufferTime(3 * 1000, null, 20),
                  filter(Boolean)
                )
                .subscribe((pendingTransitions: Array<TransitionRequest>) => {
                  if (pendingTransitions && pendingTransitions.length > 0) {
                    this.log.trace(' Processing pending transitions : ', pendingTransitions);
                    const errors = [];
                    this.recursivelyProcessPendingTransitions(pendingTransitions, 0, errors, 'Live change transition trigger');
                    // this.batchPerformTransitions(pendingTransitions);
                  }
                }
                );
            });
        }
      });

    this.deviceInfo.networkStatus.networkChange
      .subscribe(connected => {
        if (connected) {
          this.awaitingTransitionStoreService.ready()
            .then(() => {
              this.retryAwaitingTransitions();
            });
        }
      });

    setInterval(() => {
      this.awaitingTransitionStoreService.ready()
        .then(() => {
          this.log.trace('Retrying transitions after delay');
          this.retryingTransitions = false;
          if (this.pendingTransitionsIDs.length > 0) {
            this.log.error('Transitions not processed, clearing for reprocessing');
            this.pendingTransitionsIDs = [];
          }
          this.retryAwaitingTransitions();
        });
    }, 30 * 1000);

    this.mobileContextService.userSettingsObservable
      .subscribe((userSettings) => {
        this.settings = userSettings;
      });

    this.transitionErrorsSink.asObservable()
      .pipe(
        // NOTE: we should make sure to not have concurrency issue where the transition has been declined because the same transition has been done a few seconds ago
        // if we did not find the transition request in the queue, so we can assume that it has been processed and we can ignore it (not show any error message)
        filterByPromise(val => this.isTransitionRequestStillInQueue(val.transitionRequest)),
        filter((val: { error: any, transitionRequest: TransitionRequest }) => {
          const isTimeoutError = this.httpUtils.isHttpTimeoutError(val.error);
          if (isTimeoutError === true) {
            // NOTE: display a popup once (for all transitions) every 15 minutes (to avoid spamming the user)
            return this.lastTransitionTimeoutErrorTime == null || moment().diff(this.lastTransitionTimeoutErrorTime, 'milliseconds') > this.TRANSITION_TIMEOUT_ERROR_THROTTLE;
          }
          return true;
        })
      )
      .subscribe(val => {
        this.showFailedTransitionError(val);
      });
  }

  // private async simulateDelay(): Promise<void> {
  //   await new Promise(resolve => setTimeout(resolve, 30000));
  // }

  processPendingTransitions(transitions: Array<TransitionRequest>, index: number, ordersIdError: Array<string>, trace?) {
    this.log.debug('processing transitions', trace, transitions.length);
    if (transitions && transitions.length > 0) {
      // this.batchPerformTransitions(transitions)
      this.recursivelyProcessPendingTransitions(transitions, index, ordersIdError, trace);
      this.retryingTransitions = false;
    } else {
      this.retryingTransitions = false;
    }
  }

  recursivelyProcessPendingTransitions(transitions: Array<TransitionRequest>, index: number, ordersIdError: Array<string>, trace?) {
    if (transitions && transitions[index] && !(this.pendingTransitionsIDs.indexOf(transitions[index]._id) > -1)) {
      this.log.trace('recursive processing of transitions', index, transitions.length);
      const currentTransition = transitions[index];
      this.pendingTransitionsIDs.push(currentTransition._id);
      if (ordersIdError.indexOf(currentTransition.orderID) > -1) {
        this.log.trace('Skipping a transition that previously failed', currentTransition);
        // Passer à la transition suivante en skippant la courante
        this.processPendingTransitions(transitions, ++index, ordersIdError, trace);
      } else {
        this.performTransitionOrder(currentTransition, trace)
          .subscribe(
            () => {
              // console.log('performTransitionOrder next');
            },
            (err: Response) => {
              this.log.error('Transition request failed : ', err);
              ordersIdError.push(currentTransition.orderID);
            }, () => {
              this.processPendingTransitions(transitions, ++index, ordersIdError, trace);
            }
          );
      }
    } else {
      this.log.trace('No more transitions to process', trace);
      this.retryingTransitions = false;
    }
  }

  private removeFromPendingTransitionArray(id: string) {
    this.pendingTransitionsIDs.splice(this.pendingTransitionsIDs.indexOf(id), 1);
  }

  getAvailableTransitionsForOrder(order: Order): Array<Transition> {
    return this.workflowService.getAvailableTransitionForStateAndWorkflow(order.stateMachineWorkflow, order.status);
  }

  transitionOrders(orderViews: Array<OrderView>, transition: Transition, transitFromHaulerEmployeePage?: boolean): void {
    if (transition.event.transitionDetail) {
      let gatekeeper: Observable<boolean>;
      if (transition.event.transitionDetail.withValidateDistance
        && this.hauler.warningOnDelocalizedDelivery
        && this.hauler.warningOnDelocalizedDelivery.activated) {

        gatekeeper = from(
          (async (): Promise<boolean> => {
            return new Promise<boolean>(async (resolve: (value: boolean) => void, _reject: (reason?: any) => void) => {
              const position: Position = await this.getCurrentPosition();
              if (!position) {
                resolve(true);
                return;
              } else {
                const outOfRange = orderViews.find((view: OrderView) => {
                  const distance = geolib.getDistance(position, view.order.deliveryAddress.position);
                  return distance > this.hauler.warningOnDelocalizedDelivery.distance;
                });
                if (outOfRange) {
                  // alert
                  const outOfRangeDistance = this.distancePipe.transform(this.hauler.warningOnDelocalizedDelivery.distance / this.metersConverters, this.settings.distanceUnit, true);
                  const alert = await this.alertCtrl.create({
                    subHeader: this.translate.instant('transition.warning.distance.multiple', { distance: outOfRangeDistance }),
                    buttons: [{
                      handler: () => {
                        resolve(false);
                      },
                      text: this.translate.instant('actions.cancel')
                    }, {
                      handler: () => {
                        resolve(true);
                      },
                      text: this.translate.instant('actions.continue')
                    }],
                    cssClass: 'distance_warning'
                  });
                  alert.present();
                } else {
                  resolve(true);
                  return;
                }
              }
            });
          })()
        );

      } else {
        gatekeeper = of(true);
      }

      gatekeeper
        .subscribe(async proceed => {
          if (proceed) {
            const itemsModal = await this.modalCtrl.create({
              component: MultipleOrderTransitionPage,
              componentProps: {
                orderViews: orderViews,
                transition: transition,
                transitionOrderFunction: this.transitionOrder.bind(this),
                hauler: this.hauler
              }
            });
            await itemsModal.present();
          }
        });
    } else if (transitFromHaulerEmployeePage) {
      orderViews.forEach(orderView => {
        if (orderView) {
          this.transitionOrder(orderView.order, transition).subscribe(
            {
              next: () => {
              },
              error: () => {
              },
              complete: () => {
              }
            }
          );
        }
      });
    } else {
      const ordersToPersist = [];

      orderViews.forEach((view: OrderView) => {
        ordersToPersist.push(this.prepareFakeTransition(view.order, transition));
      });

      this.orderStore.batchPersistOrders(ordersToPersist)
        .subscribe(
          (ordersFaked: Array<Order>) => {
            const observableSave = [];
            ordersFaked.forEach(order => {
              // TODO use batch
              observableSave.push(this.saveTransitionToStore(order, transition));
            });
            combineLatest(observableSave).subscribe();
          });
    }
  }

  transitionOrder(order: Order, transition: Transition, data?: TransitionData, multipleContext?: boolean): Observable<any> {

    let enabledTransition: Observable<void>;
    if (!multipleContext && transition.event.transitionDetail && transition.event.transitionDetail.withValidateDistance) {

      enabledTransition = from(
        (async () => {
          const position: Position = await this.getCurrentPosition();
          if (position) {
            const now = moment();
            // NOTE: this seems to be wrong to update dateEmitted to the current datetime, as the position already has it already set
            // to the datetime when this position is constructed (at the time when it was obtained from the native device or from the browser in PWA app)
            position.dateEmitted = now.clone();
            // check if ok
            const value = firstValueFrom(this.warnOnDistanceIfNecessary(order, transition, position));
            if (!value) {
              order.local = false;
              throw new Error();
            }
          }
          return;
        })()
      );

    } else {
      enabledTransition = of(null);
    }

    const requestDetail = new Observable((subscriber: Subscriber<TransitionData>) => {
      enabledTransition.subscribe(async () => {
        if (data) {
          subscriber.next(data);
          subscriber.complete();
        } else if (transition.event.transitionDetail) {
          const update = transition.event.name === CONFIG.EDIT_PAYMENT_EVENT;
          const itemModal = await this.modalCtrl.create({
            component: TransitionDetailPage,
            componentProps: {
              order: order,
              transition: transition,
              formConfig: transition.event.transitionDetail,
              parameters: TransitionParameters.allActivated(),
              update,
              hauler: this.hauler
            }
          });
          itemModal.onDidDismiss().then(
            d => {
              if (d && d.data && d.data.transitionData) {
                subscriber.next(d.data.transitionData);
                subscriber.complete();
              } else {
                subscriber.error();
                subscriber.complete();
              }
            }
          );
          itemModal.present();
        } else {
          subscriber.next(null);
          subscriber.complete();
        }
      }, () => {
        subscriber.complete();
      });
    });

    return new Observable<any>(subscriber => {
      requestDetail
      .subscribe(async (info: TransitionData) => {
        if (info && info.impersonate) {
          // Fetch position first
          const position: Position = await this.getCurrentPosition();
          const now = moment();
          if (position) {
            // NOTE: this seems to be wrong to update dateEmitted to the current datetime, as the position already has it already set
            // to the datetime when this position is constructed (at the time when it was obtained from the native device or from the browser in PWA app)
            position.dateEmitted = now.clone();
          }
          const transitionRequest = new TransitionRequest(order.id, now.clone(), transition, 0, info, order.nextSequenceNumber, position);
          this.performImpersonateTransition(transitionRequest)
            .subscribe((tran: any) => subscriber.next(tran.order),
              err => subscriber.error(err),
              () => subscriber.complete());
        } else {
          // do transition
          subscriber.next(this.fakeAndSaveTransition(order, transition, info));
          subscriber.complete();
        }
      }, () => {
        subscriber.next(null);
        subscriber.complete();
      }, () => {
        subscriber.complete();
      });

    
    });

  }

  public retryAwaitingTransitions() {
    this.log.trace('Should we retry awaiting transitions', !this.retryingTransitions);
    if (!this.retryingTransitions) {
      this.log.debug('Retrying awaiting transitions');
      this.retryingTransitions = true;

      const trace = 'RETRY_PENDING_ATTEMPT_' + uuidv4();
      this.awaitingTransitionStoreService
        .getAllDocs()
        .subscribe((awaitingTransitions: Array<TransitionRequest>) => {
          if (awaitingTransitions && awaitingTransitions.length > 0) {
            this.log.debug('Back online, processing pending transitions', awaitingTransitions.length);
            this.recursivelyProcessPendingTransitions(awaitingTransitions, 0, [], trace);
          }
        });
    }
  }

  private fakeAndSaveTransition(order: Order, transition: Transition, data: TransitionData) {
    return this.fakeTransitionOrder(order, transition)
      .subscribe(() => this.saveTransitionToStore(order, transition, data));
  }

  // Reactivate when we are more sure about batch processing
  /*
    private batchPerformTransitions(pendingTransitions: Array<TransitionRequest>): Observable<any> {
      if (pendingTransitions && pendingTransitions.length > 0 && this.deviceInfo && this.deviceInfo.networkStatus.isConnected) {
        const url = this.endpointService.currentEndpoint + CONFIG.PATH_TRANSITIONS;
        let uploadPicturePromises = [];
        let httpTransitionRequests = [];
        pendingTransitions.forEach((transitionRequest: TransitionRequest) => {
          uploadPicturePromises.push(this.uploadPictures(transitionRequest));
          if (transitionRequest.transitionData && transitionRequest.transitionData.selectedReason) {
            transitionRequest.transitionData.selectedReason = this.translate.instant(transitionRequest.transitionData.selectedReason);
          }
          let httpTransitionRequest = new HttpTransitionRequest(transitionRequest.orderID, transitionRequest.transition.event.name, transitionRequest.date, transitionRequest.transitionData);
          httpTransitionRequests.push(httpTransitionRequest);
        });

        return new Observable(observer => {
          Observable.from(uploadPicturePromises).concatAll()
            .subscribe(null, null, () => {
              // console.log('pictures uploaded, processing rest', httpTransitionRequests);
              this.httpClient.post(url, {transitions: httpTransitionRequests})
                .map((body: Object) => plainToClass(BatchedHttpTransitionResponseModel, body), error => {
                  console.error('unable to convert response to array of HttpTransitionResponse', error);
                })
                .map((batchedResponse: BatchedHttpTransitionResponseModel) => {
                  console.log('got responses!');
                  batchedResponse.responses.forEach((transitionResponse: HttpTransitionResponse) => {
                    // Find transitionRequest
                    let transitionRequest = pendingTransitions.find(request => request.orderID.toString() === transitionResponse.order.id.toString());
                    if (transitionRequest) {
                      this.processTransitionResponse(transitionRequest, transitionResponse);
                    } else {
                      console.warn('Could not find corresponding request for response', transitionResponse);
                    }
                  });
                })
                .subscribe(
                  (value) => {
                    observer.next(value);
                  },
                  (error) => {
                    observer.error(error);
                  },
                  () => {
                    observer.complete();
                  });
            });
        });
      } else {
        return new EmptyObservable();
      }
    }
  */

  private performImpersonateTransition(transitionRequest: TransitionRequest) {
    return new Observable(subscriber =>
      this.postTransitionOrder(transitionRequest)
        .subscribe((post: Observable<object | string>) =>
          post.pipe(
            // NOTE: if there is no network or not authorized the observable returns string and not the HttpTransitionResponse object
            filter(post => post != null && typeof post === 'object'),
            map((body: object) => plainToClass(HttpTransitionResponse, body))
          )
            .subscribe(
              data => subscriber.next(data),
              err => subscriber.error(err)
            )));
  }

  // QUESTION/COMMENT: i noticed that observable is used quite often and for some cases it might be better to use Promise, because
  // (a) many operations won't emit multiple values but only one and complete (Promise like behavior)
  // (b) this is not required to subscribe to a returned observable to execute it (right now, in some places, we just subscribe
  // for this only purpose and never handle what is emitted/throw)
  // (c) may use await/async which might be more readable and easier to maintain (opininated!)
  private postTransitionOrder(transitionRequest: TransitionRequest, trace?, manualTransition = false): Observable<object | string> {
    // NOTE: isNetworkConnectedForUser is not reliable enough and sometimes it is true even though the connectivity is not present or the server is not available
    if (this.connectionStatus?.isNetworkConnected === true && this.connectionStatus?.isNetworkConnectedForUser === true) {
      if (this.tokenService && this.tokenService.oauthToken) {
        return new Observable(subscriber => {
          const url = this.endpointService.currentEndpoint + CONFIG.PATH_TRANSITION;
          this.uploadPictures(transitionRequest)
            .subscribe({
              error: (e) => {
                console.error('BUBBLE UP FAIL CATCHED', e);
                // NOTE: we don't invoke transitionNeedRetry when the transition manually triggerred from TransitionsDebugComponent
                if (manualTransition === false) {
                  const isTimeoutError = this.httpUtils.isHttpTimeoutError(e);
                  // NOTE: subscribed to transitionNeedRetry observable returned to make the observable in db-service.put method to execute
                  // otherwise the transiton request does not seem to be persisted in the db
                  this.transitionNeedRetry(transitionRequest, isTimeoutError, e).subscribe(() => console.log('subscribe ok on transitionNeedRetry'), (e) => console.log('subscribe error on transitionNeedRetry', e));
                  this.transitionErrorsSink.next({ error: e, transitionRequest: transitionRequest });
                } else {
                  // NOTE: report the error for manual transition
                  subscriber.error(e);
                }
              },
              complete: () => {
                if (transitionRequest.transitionData) {
                  if (transitionRequest.transitionData.selectedReason) {
                    transitionRequest.transitionData.selectedReason = this.translate.instant(transitionRequest.transitionData.selectedReason);
                  }
                  transitionRequest.transitionData.impersonate = transitionRequest.transition.impersonate;
                }

                const httpTransitionRequest = new HttpTransitionRequest(transitionRequest.orderID, transitionRequest.transition.event.name, transitionRequest.date, transitionRequest.transitionData, transitionRequest.sequenceNumber, transitionRequest.position);
                if (transitionRequest.retryCount > 0) {
                  this.log.debug('Redoing transition : ', transitionRequest.retryCount, transitionRequest._id, transitionRequest.orderID, transitionRequest, trace);
                }
                subscriber.next(this.httpClient.post(url, { transition: httpTransitionRequest }));
              }
            });
        });
      } else {
        // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
        transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
          `Transition failed. Not authorized`, true)
        );
        this.log.debug('Postponing transition : Not authorized.');
        return from('Not authorized.');
      }
    } else {
      // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
      transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
        `Postponing transition : no internet connection`, false)
      );
      this.log.debug('Postponing transition : no internet connection.');
      return from('No internet connection.');
    }
  }

  public performTransitionOrder(transitionRequest: TransitionRequest, trace?: any, manualTransition = false): Observable<any> {
    return new Observable(subscriber => {
      this.postTransitionOrder(transitionRequest, trace, manualTransition)
        .pipe(
          concatMap((post: Observable<object | string>) => post),
          // NOTE: if there is no network or not authorized the observable returns string and not the HttpTransitionResponse object
          filter(post => post != null && typeof post === 'object'),
          map((body: object) => {
            try {
              return plainToClass(HttpTransitionResponse, body);
            } catch (error) {
              this.log.error('Unable to convert response from server to HttpTransitionResponse : ', error);
              // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
              transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(`Unable to convert response from server to HttpTransitionResponse: ${this.commonUtils.safeStringify(error)}`, true));
              // NOTE: we don't invoke transitionFail for the transition manually triggerred from TransitionsDebugComponent
              if (manualTransition === false) {
                this.transitionFail(transitionRequest, {
                  error: error,
                  message: 'Unable to convert response from server to HttpTransitionResponse'
                }).subscribe(() => console.log('subscribe ok on transitionFail'), (e) => console.log('subscribe error on transitionFail', e));
                this.transitionErrorsSink.next({ error, transitionRequest: transitionRequest });
              } else {
                throw new AppError(false, 'Unable to convert response from server to HttpTransitionResponse', error);
              }
            }
          }),
          map(async (transitionResponse: HttpTransitionResponse) => {
            try {
              await firstValueFrom(this.processTransitionResponse(transitionRequest, transitionResponse, manualTransition));
            } catch (error) {
              this.log.error('Unable to process http response from server : ', error);
              // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
              transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
                `Unable to process http response from server: ${this.commonUtils.safeStringify(error)}`, true)
              );
              // NOTE: we don't invoke transitionFail for the transition manually triggerred from TransitionsDebugComponent
              if (manualTransition === false) {
                this.transitionFail(transitionRequest, {
                  error: error,
                  message: 'Unable to process http response from server'
                }).subscribe(() => console.log('subscribe ok on transitionFail'), (e) => console.log('subscribe error on transitionFail', e));
                this.transitionErrorsSink.next({ error, transitionRequest: transitionRequest });
              } else {
                throw new AppError(false, 'Unable to process http response from server', error);
              }
            }
          })
        )
        .subscribe({
          next: (_what) => {
            subscriber.complete();
          },
          error: (err: Response | AppError) => {
            this.log.error('Transition request failed : ', err);
            if (manualTransition === false) {
              this.transitionErrorsSink.next({ error: err, transitionRequest: transitionRequest });
            }
            // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
            transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
              `Transition request failed: ${this.commonUtils.safeStringify(err)}`, true)
            );
            const isTimeoutError = this.httpUtils.isHttpTimeoutError(err);
            if (isTimeoutError) {
              this.log.error('Reason : no internet', err);
              // NOTE: we don't increment timeout count for the transition manually triggerred from TransitionsDebugComponent
              if (manualTransition === false) {
                transitionRequest.incrementTimeoutCount();
              }
              subscriber.error('Reason : no internet');
            } else {
              this.log.error('Reason : unknown');
              // NOTE: we don't invoke transitionFail for the transition manually triggerred from TransitionsDebugComponent
              if (manualTransition === false) {
                this.transitionFail(transitionRequest, {
                  error: err,
                  message: 'Request failed for unknown reason'
                }).subscribe();
              }
              subscriber.error('Transition request failed : ' + err);
            }
          }
        });
    });
  }

  /**
   * Method to check if the transition request is still in the queue (still needs to be transitioned/processed)
   * @param transitionRequest
   *
   * @returns true if the transition request is still in the queue, otherwise false
   */
  public async isTransitionRequestStillInQueue(transitionRequest: TransitionRequest): Promise<boolean> {
    await this.awaitingTransitionStoreService.ready();
    const awaitingTransitions: Array<TransitionRequest> = await firstValueFrom(this.awaitingTransitionStoreService.getAllDocs());
    const isTransitionRequestStillInQueue = awaitingTransitions.some(pendingTransition => pendingTransition._id === transitionRequest._id);
    return isTransitionRequestStillInQueue;
  }
  public async showFailedTransitionError(val: { error: any, transitionRequest: TransitionRequest }): Promise<void> {
    const isTimeoutError = this.httpUtils.isHttpTimeoutError(val.error);
    if (isTimeoutError === true) {
      // NOTE: need to record the time when we displayed the error caused by the timeout
      this.lastTransitionTimeoutErrorTime = moment();
    }
    const appError = this.commonUtils.convertToAppError(val.error);
    if (appError && appError.handled === false && appError.message) {
      // NOTE: if another error popup is still displayed we hide it
      if (this.failedTransitionErrorDialog != null) {
        await this.failedTransitionErrorDialog.dismiss();
        this.failedTransitionErrorDialog = null;
      }
      const errorMessage = `Transition request failed: ${appError.message}`;
      const options = {
        header: this.translate.instant('dialogs.headers.error').toUpperCase(),
        cssClass: 'error-dialog',
        message: errorMessage,
        buttons: [
          {
            text: this.translate.instant('actions.ok').toUpperCase(),
            role: 'cancel',
            handler: _ => {
              this.failedTransitionErrorDialog = null;
            }
          }
        ]
      };
      this.failedTransitionErrorDialog = await this.alertCtrl.create(options);
      await this.failedTransitionErrorDialog.present();
    }
  }

  private fakeTransitionOrder(order: Order, transition: Transition): Observable<Order> {
    this.log.trace('Fake transition on order ', order, transition);
    return new Observable(subscriber => {
      this.orderStore.persistOrder(this.prepareFakeTransition(order, transition), 'TransitionService.fakeTransitionOrder')
        .subscribe(
          orderDb => {
            subscriber.next(orderDb);
            subscriber.complete();
          },
          reject => {
            this.log.debug('Order save rejected (for fake transition).', reject);
            // FIXME : when managing conflicts, need to be rewritten because the transition will be lost. Revisit when webapp is not master anymore (PM-185)
            this.orderSync.httpGetAndPersistOrder(order.id)
              .subscribe(
                instance => {
                  subscriber.next(instance);
                },
                err => {
                  subscriber.error(err);
                }
                , () => {
                  subscriber.complete();
                });
          });
    });
  }

  private saveTransitionToStore(order: Order, transition: Transition, transitionData?: TransitionData): Promise<Transition | Order> {

    return new Promise(async (resolve) => {
      const position: Position = await this.getCurrentPosition();
      const now = moment();
      if (position) {
        // NOTE: this seems to be wrong to update dateEmitted to the current datetime, as the position already has it already set
        // to the datetime when this position is constructed (at the time when it was obtained from the native device or from the browser in PWA app)
        position.dateEmitted = now.clone();
      }
      const transitionRequest = new TransitionRequest(order.id.toString(), now.clone(), transition, this.transitionsSequence++, transitionData, order.nextSequenceNumber, position);
      this.log.trace('Saving transition to store', transitionRequest);
      this.awaitingTransitionStoreService.put(transitionRequest)
        .subscribe(() => {
          return resolve(transition);
          759
        }, error => {
          this.log.error('Unable to save transition request : ', transitionRequest, error);
          return resolve(order);
        });
    });
  }

  private uploadPictures(transitionRequest: TransitionRequest): Observable<any> {
    if (transitionRequest.transitionData && transitionRequest.transitionData.pictures && transitionRequest.transitionData.pictures.length > 0) {
      const observables: Array<Observable<any>> = [];
      for (let i = 0; i < transitionRequest.transitionData.pictures.length; i++) {
        const picture: Picture = transitionRequest.transitionData.pictures[i];
        if (!picture.uploaded) {
          const observable = this.uploadPicture(transitionRequest, picture);
          observables.push(observable);
        } else {
          observables.push(EMPTY);
        }
      }
      return concat(...observables).pipe(toArray());
    } else {
      return EMPTY;
    }
  }

  private uploadPicture(transitionRequest: TransitionRequest, picture: Picture): Observable<any> {
    this.log.debug('uploading Picture', transitionRequest);
    return new Observable(subscriber => {
      // MAPP-163 Pictures taken on mobile appears duplicated when uploaded to S3
      // Workaround HTTP 503 / Slow down from AWS S3 when uploading in parallel
      this.signUrlForPickupPrescriptionPicture(transitionRequest.orderID, picture)
        .subscribe((preSignedURLResponse: PreSignedURLResponse) => {
          picture.filename = preSignedURLResponse.filename;
          picture.path = preSignedURLResponse.path;
          this.transferPicture(preSignedURLResponse.url, picture)
            .subscribe({
              next: result => {
                subscriber.next(result);
                subscriber.complete();
              },
              error: error => {
                console.error('FAILED UPLOAD CATCHED');
                subscriber.error(error);
              }
            });
        });
    });
  }

  private signUrlForPickupPrescriptionPicture(orderID: string, picture: Picture): Observable<any> {
    const url = this.endpointService.currentEndpoint + CONFIG.PATH_PRESIGNED_URL;

    let params = new HttpParams();
    params = params.append('id', orderID);
    params = params.append('extension', picture.extension);
    params = params.append('mimeType', picture.mimeType);

    return this.httpClient.get(url, { params: params })
      .pipe(
        map((body: object) => plainToClass(PreSignedURLResponse, body))
      );
  }

  private transferPicture(presignedURL: string, picture: Picture): Observable<any> {
    return new Observable<Picture>(observer => {
      const blob = this.b64toBlob(picture.base64String, picture.mimeType);
      const oReq = new XMLHttpRequest();

      // TEST: emulate failed picture upload
      // oReq.timeout = 1;

      oReq.onload = (ev => {
        this.log.debug('Uploading on load', ev);
        // @ts-ignore
        if (ev.currentTarget && ev.currentTarget.status === 200) {
          picture.uploaded = true;
          observer.next(picture);
          observer.complete();
        } else {
          this.log.error('Uploading failed, status code not 200', JSON.stringify(ev, null, 2));
          observer.error(ev);
        }
      });
      oReq.onerror = (ev => {
        this.log.error('Uploading onerror', JSON.stringify(ev, null, 2));
        observer.error(ev);
      });
      oReq.onabort = (ev => {
        this.log.error('Uploading onabort', JSON.stringify(ev, null, 2));
        observer.error(ev);
      });
      oReq.ontimeout = (ev => {
        this.log.error('Uploading ontimeout', JSON.stringify(ev, null, 2));
        observer.error(ev);
      });
      oReq.onreadystatechange = (ev => {
        this.log.info('Uploading, state change', JSON.stringify(ev, null, 2));
      });
      try {
        this.log.info('Uploading, opening url', presignedURL);
        // if ((Math.floor(Math.random() * 3) + 1) === 1) {
        // if (this.temp < 6) {
        //   console.log('ARTIFICIAL FAIL');
        // throw new Error('dummyFail');)
        // presignedURL = presignedURL.replace('Signature', 'Sigature');
        // this.temp ++;
        // }
        oReq.open('PUT', presignedURL, true);
        this.log.debug('Uploading sending blob');
        oReq.send(blob);
      } catch (e) {
        this.log.error('Uploading failed', JSON.stringify(e, null, 2));
        observer.error(e);
      }
    });
  }

  private b64toBlob(b64Data, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  private transitionDeclined(order: Order, transitionRequest: TransitionRequest): Observable<any> {
    this.log.error('Transition has been declined, persisting last order version : ', order.id, transitionRequest, order);
    this.orderStore.persistOrder(order, 'TransitionService.transitionDeclined')
      .subscribe({
        error: err => {
          this.log.error('problem persisting order', order, err);
        }
      });
    this.removeFromPendingTransitionArray(transitionRequest._id);
    return this.awaitingTransitionStoreService.remove(transitionRequest);
  }

  private transitionNeedRetry(transitionRequest: TransitionRequest, isTimeoutError: boolean, error?: any): Observable<any> {
    this.log.debug('Transition has failed but is recoverable, increasing retry count : ', transitionRequest.orderID, transitionRequest);
    if (isTimeoutError === true) {
      // NOTE: increate timeout count (for debugging purposes)
      transitionRequest.incrementTimeoutCount();
    } else {
      // NOTE: don't increate the main retry count for timeout error
      transitionRequest.incrementRetryCount();
    }
    // NOTE: no need to remove transitionRequest from pendingTransitionArray, it will be removed when the transition is completed or max retry count is reached
    // this.removeFromPendingTransitionArray(transitionRequest._id);
    if (isTimeoutError) {
      transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
        `Transition has failed due to timeout but is recoverable, increasing timeout count`, true)
      );
    } else if (error) {
      transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
        `Transition has failed but is recoverable, increasing retry count. error: ${this.commonUtils.safeStringify(error)}`, true)
      );
    }
    return this.awaitingTransitionStoreService.put(transitionRequest);
  }

  private transitionFail(transitionRequest: TransitionRequest, reason: any): Observable<any> {
    this.log.error('Transition has failed for an unknown reason : ', transitionRequest, reason);

    transitionRequest.error = reason;
    transitionRequest.errorDate = moment();
    transitionRequest.incrementRetryCount();

    this.orderStore.get(transitionRequest.orderID)
      .subscribe((order: Order) => {
        // Putting back the order in the initial status
        const orderCloned: Order = classToClass(Order, order);
        orderCloned.status = transitionRequest.transition.from.name;
        this.orderStore.persistOrder(orderCloned, 'TransitionService.transitionFail').subscribe();

        // Throw reason to sentry
        this.reportingService.sendReport('Failed transition request.', {
          transitionRequest: classToPlain(transitionRequest),
          reason: JSON.stringify(reason)
        });
      });
    // NOTE: no need to remove transitionRequest from pendingTransitionArray, it will be removed when the transition is completed or max retry count is reached
    // this.removeFromPendingTransitionArray(transitionRequest._id);
    return this.awaitingTransitionStoreService.put(transitionRequest);
  }

  private prepareFakeTransition(order: Order, transition: Transition): Order {
    const orderCloned = classToClass(Order, order);
    orderCloned.status = transition.to.name;
    orderCloned.local = true;
    orderCloned.blocked = transition.needsCompletion;
    return orderCloned;
  }

  private processTransitionResponse(transitionRequest: TransitionRequest, transitionResponse: HttpTransitionResponse, manualTransition = false): Observable<any> {
    this.log.debug('Processing response on transition for order', transitionRequest.orderID);
    return new Observable((subscriber) => {
      if (transitionResponse.ok || transitionResponse.status === 200) {  // CHeck for other statuses et message
        this.removeFromPendingTransitionArray(transitionRequest._id);
        this.awaitingTransitionStoreService.remove(transitionRequest).subscribe();
        this.orderStore.persistOrder(transitionResponse.order, 'TransitionService => performTransitionOrder').subscribe();
      } else if (transitionRequest.retryCount >= CONFIG.MAX_TRANSITION_RETRY) {
        // NOTE: report to sentry that MAX_TRANSITION_RETRY is reached
        this.reportingService.sendReport('Reached MAX_TRANSITION_RETRY for the transition request.', {
          request: classToPlain(transitionRequest),
          response: classToPlain(transitionResponse)
        });
        const errorMessage = this.translate.instant('transitions.messages.reached-max-transition-retry-error-message');
        // NOTE: we don't remove pending transition when the transition manually triggerred from TransitionsDebugComponent
        if (manualTransition === true) {
          // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
          transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
            `Reached MAX_TRANSITION_RETRY for the transition request: ${this.commonUtils.safeStringify({
              request: classToPlain(transitionRequest),
              response: classToPlain(transitionResponse)
            })}`, true)
          );
          subscriber.error(errorMessage);
          return;
        }
        this.removeFromPendingTransitionArray(transitionRequest._id);
        this.awaitingTransitionStoreService.remove(transitionRequest).subscribe();
        this.transitionErrorsSink.next({ error: errorMessage, transitionRequest: transitionRequest });
      } else if (transitionResponse.status === 409) {
        // NOTE: report to sentry that transition is declined
        this.reportingService.sendReport('Transition has been declined.', {
          request: classToPlain(transitionRequest),
          response: classToPlain(transitionResponse)
        });
        const errorMessage = this.translate.instant('transitions.messages.transition-has-been-declined-error-message');
        // NOTE: we don't invoke transitionDeclined when the transition manually triggerred from TransitionsDebugComponent
        if (manualTransition === true) {
          // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
          transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
            `Transition has been declined.: ${this.commonUtils.safeStringify({
              request: classToPlain(transitionRequest),
              response: classToPlain(transitionResponse)
            })}`, true)
          );
          subscriber.error(errorMessage);
          return;
        }
        this.transitionDeclined(transitionResponse.order, transitionRequest).subscribe();
        this.transitionErrorsSink.next({ error: errorMessage, transitionRequest: transitionRequest });
      } else if (transitionResponse.status === 400 && (transitionResponse.message && transitionResponse.message.indexOf('org.hibernate.StaleObjectStateException') > -1) && transitionRequest.retryCount < CONFIG.MAX_TRANSITION_RETRY) {
        // NOTE: report to sentry that transition failed with status 400
        this.reportingService.sendReport('Transition has failed with status 400.', {
          request: classToPlain(transitionRequest),
          response: classToPlain(transitionResponse)
        });
        const errorMessage = this.translate.instant('transitions.messages.transition-failed-with-http-400-error');
        // NOTE: we don't invoke transitionNeedRetry when the transition manually triggerred from TransitionsDebugComponent
        if (manualTransition === true) {
          // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
          transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
            `Transition failed with status 400.: ${this.commonUtils.safeStringify({
              request: classToPlain(transitionRequest),
              response: classToPlain(transitionResponse)
            })}`, true)
          );
          subscriber.error(errorMessage);
          return;
        }
        this.transitionNeedRetry(transitionRequest, false).subscribe(() => console.log('subscribe ok on transitionNeedRetry'), (e) => console.log('subscribe error on transitionNeedRetry', e));
        this.transitionErrorsSink.next({ error: errorMessage, transitionRequest: transitionRequest });
      } else {
        this.log.debug('Transition failed', transitionResponse);
        this.reportingService.sendReport('Transition failed fallthrough', {
          request: classToPlain(transitionRequest),
          response: classToPlain(transitionResponse)
        });
        this.log.warn('Transition failed fallthrough treatment, may need inspection', transitionRequest.orderID);
        const errorMessage = this.translate.instant('transitions.messages.transition-failed-fallthrough-treatment-error-message');
        // NOTE: we don't invoke transitionFail when the transition manually triggerred from TransitionsDebugComponent
        if (manualTransition === true) {
          // NOTE: add log to transitionRequest to view on TransitionDetailDebugComponent page
          transitionRequest.transitionRequestLogs.push(new TransitionRequestLog(
            `Transition failed fallthrough: ${this.commonUtils.safeStringify({
              request: classToPlain(transitionRequest),
              response: classToPlain(transitionResponse)
            })}`, true)
          );
          subscriber.error(errorMessage);
          return;
        }
        this.transitionFail(transitionRequest, transitionResponse).subscribe();
        this.transitionErrorsSink.next({ error: errorMessage, transitionRequest: transitionRequest });
      }
      // QUESTION: Should we always complete as below, even if processTransitionResponse failed?
      subscriber.complete();
    });
  }

  private async getCurrentPosition(): Promise<Position | undefined> {
    const lastKnownPosition = this.backgroundGeolocationService.getLastKnownPosition();
    if (lastKnownPosition) {
      return lastKnownPosition;
    }
    const currentPosition: Position | undefined = await this.backgroundGeolocationService.recheckPosition();
    return currentPosition;
  }

  private warnOnDistanceIfNecessary(order: Order, transition: Transition, position: Position): Observable<boolean> {
    if (!order || !transition || !position) {
      return of(true); // nothing to do on empty items
    }
    return new Observable<boolean>(subscriber => {
      if (transition.event.transitionDetail && transition.event.transitionDetail.withValidateDistance
        && this.hauler.warningOnDelocalizedDelivery && this.hauler.warningOnDelocalizedDelivery.activated) {
        const distance = geolib.getDistance(position, order.deliveryAddress.position); // meters
        if (distance > this.hauler.warningOnDelocalizedDelivery.distance) {
          const outOfRangeDistance = this.distancePipe.transform(this.hauler.warningOnDelocalizedDelivery.distance / this.metersConverters, this.settings.distanceUnit, true);
          const title = this.translate.instant('transition.warning.distance.subTitle', { distance: outOfRangeDistance });
          this.alertCtrl.create({
            subHeader: title,
            buttons: [{
              handler: () => {
                // alert.dismiss();
                subscriber.next(false);
                subscriber.complete();
              },
              text: this.translate.instant('actions.cancel')
            }, {
              handler: () => {
                subscriber.next(true);
                subscriber.complete();
              },
              text: this.translate.instant('actions.continue')
            }],
            cssClass: 'distance_warning'
          })
            .then(value => value.present());
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });
  }

}
