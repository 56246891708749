import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {SignaturePage} from '@app/pages/signature/signature.page';
import {TransitionDataFormConverter} from '@app/pages/transition-detail/transition-data-form.converter';
import {TransitionDetailPage} from '@app/pages/transition-detail/transition-detail.page';
import {TransitionParameters} from '@app/pages/transition-detail/transition-parameters.model';
import {ToastController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {Hauler} from '@models/business/hauler.model';
import {Order} from '@models/business/order.model';
import {Picture, TransitionData} from '@models/business/transition-data.model';
import {TransitionDetail} from '@models/business/transition-detail.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {Transition} from '@models/workflow/transition.model';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {HaulerEmployeeProvider} from '@services/hauler-employee/hauler-employee-provider.service';
import {HaulerEmployee} from '@services/hauler-employee/hauler-employee.model';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {PictureService} from '@services/picture/picture.service';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {takeUntil} from 'rxjs/operators';

const CONFIG = {
  GA: {
    PAGE_NAME: 'MultipleOrderTransitionPage',
  }
};


@Component({
  templateUrl: './multiple-order-transition.page.html',
  styleUrls: ['./multiple-order-transition.page.scss'],
})
export class MultipleOrderTransitionPage {

  private unsubscribe: Subject<void> = new Subject<void>();
  private transitionIsNotRunning = true;
  orderViews: Array<OrderView>;
  transition: Transition;
  transitionOrderFunction: Function;
  title: string;
  transitionForm: UntypedFormGroup;
  config: TransitionDetail;
  private employees: Array<HaulerEmployee>;
  hauler: Hauler;
  private fdc: TransitionDataFormConverter;
  batchTitle: string;
  singleTitle: string;
  public connected = true;
  private toast: any = null;
  private pageActive: boolean = null;
  private picturesLocation: string;
  private lastDeliveryRecipient: DeliveryRecipient;
  private lastSignature: any;
  clonedOrderViews: Array<OrderView>;

  constructor(private modalCtrl: ModalController,
              private employeesService: HaulerEmployeeProvider,
              private navParams: NavParams,
              private translateService: TranslateService,
              private picture: PictureService,
              private connectionStatusService: ConnectionStatusService,
              private orderStoreService: OrderStoreService,
              private toastCtrl: ToastController,
              private ga: MblsAnalyticsService,
              private mobileContextService: MobileContextService,
              private log: LogService) {
    this.orderViews = this.navParams.get('orderViews');
    this.transition = this.navParams.get('transition');
    this.hauler = this.navParams.get('hauler');
    this.config = this.transition.event.transitionDetail;

    // If only orders with locker data, hide some items
    const dummyOrder = new Order();
    const views = this.orderViews.filter(view => view.order.lockerData);

    if (views && views.length == this.orderViews.length) {
      dummyOrder.lockerData = views[0].order.lockerData;
    }
    this.fdc = new TransitionDataFormConverter(this.config, dummyOrder, this.hauler);

    this.transitionForm = this.fdc.toForm();
    this.clonedOrderViews = Object.assign([], this.orderViews);
    this.transitionOrderFunction = this.navParams.get('transitionOrderFunction');
    // this.title = 'transitions.titles.' + this.transition.event.name;
    this.batchTitle = this.translateService.instant('transitions.titles.batch');
    this.title = this.batchTitle;
    this.singleTitle = this.translateService.instant('transitions.titles.single');

    this.orderViews.forEach((view: OrderView) => {
      view.updateValidity(this.config, this.hauler);
    });

  }

  ionViewWillEnter() {
    if (this.config.withHaulerChoice) {
      this.employeesService.getAvailableEmployees()
        .subscribe(data => {
          this.employees = data;
        });
      this.pageActive = true;
    }

    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.connected = next.isNetworkConnectedForUser;
      });

    this.orderStoreService.getLiveChanges()
      .pipe(
        takeUntil(this.unsubscribe),
        filter(Boolean),
        filter(() => this.pageActive),
        filter(() => this.transitionIsNotRunning)
      )
      .subscribe((orderChange: any) => {
        const localOrder = this.orderViews.find(value => value.order.id.toString() === orderChange.doc.id.toString());
        const idx = this.orderViews.indexOf(localOrder);

        if (localOrder) {
          if (orderChange.deleted || (localOrder.order.status !== orderChange.doc.status)) {
            let message = this.translateService.instant('messages.order.label') + ' ' + localOrder.order.reference + ' : ';
            if (orderChange.deleted) {
              message += this.translateService.instant('messages.order.order_not_available');
            } else if (localOrder.order.status !== orderChange.doc.status) {
              message += this.translateService.instant('messages.order.order_has_changed') + ': (' + this.translateService.instant('workflows.status.' + orderChange.doc.status.toLowerCase()) + ')';
            }

            localOrder.resetTransitionStatus();

            if (idx > -1) {
              this.orderViews.splice(idx, 1);
            }

            if (this.toast) {
              try {
                this.toast.dismiss();
              } catch (e) {
              }
            }
            this.toast = this.toastCtrl.create({
              message: message,
              position: 'middle',
              buttons: [{
                text: this.translateService.instant('actions.close'),
                role: 'cancel'
              }],
              duration: 3 * 1000,
              cssClass: 'processing-toast'
            });

            this.toast.onDidDismiss((data, role) => {
              if (role === 'close') {
                if (this.orderViews.length === 0) {
                  // no orders left
                  this.dismiss();
                }
              }
            });
            this.toast.present();
          } else {
            localOrder.order = orderChange.doc;
            localOrder.updateValidity(this.config, this.hauler);
            this.orderViews.splice(idx, 1, localOrder);
          }
        }
      });


    this.mobileContextService.userProfileObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userProfile => {
        this.picturesLocation = 'mbls_tp_' + userProfile.haulerEmployeeUuid;
      });

  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  ionViewWillLeave() {
    this.unsubscribe.next();
    this.pageActive = false;
    if (this.toast) {
      try {
        this.toast.dismiss();
      } catch (e) {
      }
      this.toast = null;
    }
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  get signatureImage() {
    const si = this.transitionForm.get('signatureImage');
    return si || null;
  }

  get doorCode() {
    const field = this.transitionForm.get('doorCode');
    return field || null;
  }

  dismiss() {
    // Cleanup transition data for all views
    if (this.orderViews) {
      this.orderViews.forEach((view: OrderView) => {
        view.resetTransitionStatus();
      });
    }
    this.modalCtrl.dismiss();
  }

  async transitionOrder(orderView: OrderView) {
    // Open window with data
    const modal = await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.singleTitle,
        order: orderView.order,
        transition: this.transition,
        formConfig: this.config,
        formData: orderView.transitionData,
        parameters: TransitionParameters.allActivated().deactivateTransitionFooter(),
        hauler: this.hauler
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        orderView.transitionData = data.data.transitionData;
        orderView.updateValidity(this.config, this.hauler);
      }
    });
    await modal.present();
  }

  activate(callback: Subject<boolean>) {
    this.transitionIsNotRunning = false;
    this.orderViews.forEach((view: OrderView) => {
      view.updateValidity(this.config, this.hauler);
      if (view.valid) {
        this.transitionOrderFunction(view.order, this.transition, view.transitionData || new TransitionData(), true)
          .subscribe(() => {
            const viewInClonedOrderViews = this.clonedOrderViews.find(x => x === view);
            this.clonedOrderViews.splice(this.clonedOrderViews.indexOf(viewInClonedOrderViews), 1);
            if (this.clonedOrderViews.length < 1) {
              this.dismiss();
            }
          });
      }
    });
    this.orderViews = Object.assign([], this.clonedOrderViews);
    callback.next(true);
  }

  async selectHauler() {
    const modal = await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: new Order(),
        transition: this.transition,
        formConfig: this.config,
        parameters: TransitionParameters.allDeactivated().activateHauler(),
        hauler: this.hauler
      }
    });
    modal.onDidDismiss().then(
      (data) => {
        if (data && data.data) {
          const transitionData: TransitionData = data.data.transitionData;
          this.orderViews.forEach((view: OrderView) => {
            view.prepareForData();
            view.transitionData.haulerChoice = transitionData.haulerChoice;
            const employee: HaulerEmployee = this.employees.find((he: HaulerEmployee) => he.id.toString() === transitionData.haulerChoice.toString());
            if (employee) {
              view.transitionData.haulerChoiceName = employee.fullName;
            }
            view.updateValidity(this.config, this.hauler);
          });
        }
      }
    );
    await modal.present();
  }

  async selectReasonChoicesOther() {
    const modal = await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: new Order(),
        transition: this.transition,
        formConfig: this.config,
        parameters: TransitionParameters.allDeactivated().activateReasonChoicesOther(),
        hauler: this.hauler
      }
    });

    modal.onDidDismiss().then(
      (event) => {
        if (event && event.data) {
          const transitionData: TransitionData = event.data.transitionData;
          this.orderViews.forEach((view: OrderView) => {
            view.prepareForData();
            view.transitionData.reason = transitionData.reason;
            view.transitionData.selectedReason = transitionData.selectedReason;
            view.updateValidity(this.config, this.hauler);
          });
        }
      }
    );

    await modal.present();
  }

  async selectReason() {
    const modal = await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: new Order(),
        transition: this.transition,
        formConfig: this.config,
        parameters: TransitionParameters.allDeactivated().activateReasonChoices(),
        hauler: this.hauler
      }
    });

    modal.onDidDismiss().then(
      (event) => {
        if (event && event.data) {
          const transitionData: TransitionData = event.data.transitionData;
          this.orderViews.forEach((view: OrderView) => {
            view.prepareForData();
            view.transitionData.reason = transitionData.reason;
            view.transitionData.selectedReason = transitionData.selectedReason;
            view.updateValidity(this.config, this.hauler);
          });
        }
      }
    );

    await modal.present();
  }

  async selectSignature() {
    const modal = await this.modalCtrl.create({
      component: SignaturePage,
      componentProps: {
        title: this.batchTitle
      }
    });
    modal.onDidDismiss().then(
      data => {
        if (data && data.data) {
          this.signatureImage.setValue(data.data.signature);
          this.lastSignature = data.data.signature;
          this.orderViews.forEach((view: OrderView) => {
            view.prepareForData();
            view.transitionData.signatureImage = data.data.signature;
            view.updateValidity(this.config, this.hauler);
          });
        }
      }
    );
    await modal.present();
  }

  removeSignature() {
    this.signatureImage.setValue(null);
    this.lastSignature = null;
    this.orderViews.forEach((view: OrderView) => {
      view.prepareForData();
      view.transitionData.signatureImage = null;
      view.updateValidity(this.config, this.hauler);
    });
  }

  async selectComment() {
    await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: new Order(),
        transition: this.transition,
        formConfig: this.config,
        parameters: TransitionParameters.allDeactivated().activateComment(),
        hauler: this.hauler
      }
    })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((data) => {
          if (data && data.data) {
            const transitionData: TransitionData = data.data.transitionData;
            this.orderViews.forEach((view: OrderView) => {
              view.prepareForData();
              view.transitionData.comment = transitionData.comment;
              view.updateValidity(this.config, this.hauler);
            });
          }
        });
      });
  }

  async selectInterlocutor() {
    const formData = await new TransitionData();
    formData.signatureImage = this.lastSignature;
    formData.deliveryRecipient = this.lastDeliveryRecipient;
    const modal = await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: new Order(),
        transition: this.transition,
        formConfig: this.config,
        formData: formData,
        parameters: TransitionParameters.allDeactivated().activateInterlocutor(),
        hauler: this.hauler
      }
    });

    modal.onDidDismiss()
      .then(
        (event) => {
          if (event && event.data) {
            const transitionData: TransitionData = event.data.transitionData;
            this.lastDeliveryRecipient = transitionData.deliveryRecipient;
            this.lastSignature = transitionData.signatureImage;
            this.orderViews.forEach((view: OrderView) => {
              view.prepareForData();
              view.transitionData.deliveryRecipient = transitionData.deliveryRecipient;
              view.transitionData.signatureImage = transitionData.signatureImage;
              view.updateValidity(this.config, this.hauler);
            });
          }
        }
      );

    await modal.present();
  }

  async selectPayment() {
    const order = new Order();
    order.amount = 0.0;
    await this.modalCtrl.create({
      component: TransitionDetailPage,
      componentProps: {
        title: this.batchTitle,
        order: order,
        transition: this.transition,
        formConfig: this.config,
        parameters: TransitionParameters.allDeactivated().activateNoCheckingPayment(),
        hauler: this.hauler,
        orderViews: this.orderViews
      }
    })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((data) => {
          if (data && data.data) {
            const transitionData: TransitionData = data.data.transitionData;
            this.orderViews.forEach((view: OrderView) => {
              view.prepareForData();
              view.transitionData.payments = transitionData.payments;
              view.updateValidity(this.config, this.hauler);
            });
          }
        });
      });
  }

  selectPicture() {
    this.picture.takePicture(this.picturesLocation)
      .then((picture: Picture) => {
        this.orderViews.forEach((view: OrderView) => {
          view.prepareForData();
          view.transitionData.addPicture(picture);
          view.updateValidity(this.config, this.hauler);
        });
        this.fdc.addPicture(picture);
      }, () => {
        // nothing to do, taking picture cancelled
      });
  }

  get expectedTotal(): number {
    return this.orderViews.reduce((previous, view) => previous + view.order.amount, 0);
  }

  get calculatedTotal(): number {
    return this.orderViews.reduce((previous, view) => previous + (view.transitionData ? (view.transitionData.payments.reduce((sum, payment) => payment.collectedAmount, 0)) : 0), 0);
  }

}
