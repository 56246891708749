import {Address} from '@models/business/address.model';
import {Bag} from '@models/business/bag.model';
import {Client} from '@models/business/client.model';
import {DeliveryRecipient} from '@models/business/delivery-recipient.model';
import {LockerData} from '@models/business/locker-data.model';
import {OrderManifest} from '@models/business/order-manifest.model';
import {Tag} from '@models/business/tag.model';
import {Traceability} from '@models/business/traceability.model';
import {Payment} from '@models/business/transition-data.model';
import {OrderMessage} from '@models/push-messages/order-message.model';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {Any} from 'json2typescript';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('Order')
export class Order {

  @JsonProperty('_id', String, true)
  _id: string = undefined;

  @JsonProperty('_rev', String, true)
  _rev: string = undefined;

  @JsonProperty('_rev_tree', Any, true)
  _rev_tree: any = undefined;

  /**
   * Used to flag order to delete when doing a full sync (orders to delete = orders not in the backend response)
   */
  @JsonProperty('fullSyncFlag', String, true)
  fullSyncFlag: string = undefined;

  @JsonProperty('id', Any)
  id: any = undefined;

  @JsonProperty('version', Number, true)
  version: number = undefined;

  @JsonProperty('reference', String, true)
  reference: string = undefined;

  @JsonProperty('deliveryDate', MomentDateConverter, true)
  deliveryDate: moment.Moment = undefined;

  @JsonProperty('calculatedArrivalTime', MomentDateConverter, true)
  calculatedArrivalTime: moment.Moment = undefined;

  @JsonProperty('stateMachineWorkflow', String, true)
  stateMachineWorkflow: string = undefined;

  @JsonProperty('status', String, true)
  status: string = undefined;

  @JsonProperty('tenantId', Number, true)
  tenantId: number = undefined;

  @JsonProperty('tenantName', String, true)
  tenantName: string = undefined;

  @JsonProperty('tenantWithAssignation', Boolean, true)
  tenantWithAssignation: boolean = undefined;

  @JsonProperty('paymentType', String, true)
  paymentType: string = undefined;

  @JsonProperty('amount', Number, true)
  amount: number = undefined;

  @JsonProperty('notes', String, true)
  notes: string = undefined;

  @JsonProperty('desiredArrivalTime', String, true)
  desiredArrivalTime: string = undefined;

  @JsonProperty('desiredArrivalTimeHour', String, true)
  desiredArrivalTimeHour: string = undefined;

  @JsonProperty('desiredArrivalTimeHourEnd', String, true)
  desiredArrivalTimeHourEnd: string = undefined;

  @JsonProperty('averageDeliveryMoment', String, true)
  averageDeliveryMoment: string = undefined;

  @JsonProperty('client', Client, true)
  client: Client = undefined;

  @JsonProperty('deliveryAddress', Address, true)
  deliveryAddress: Address = undefined;

  @JsonProperty('distance', Number, true)
  distance: number = undefined;

  @JsonProperty('distanceUnit', String, true)
  distanceUnit: string = undefined;

  @JsonProperty('bearing', String, true)
  bearing: string = undefined;

  @JsonProperty('tags', [Tag], true)
  tags: Array<Tag> = [];

  @JsonProperty('bags', [Bag], true)
  bags: Array<Bag> = [];

  @JsonProperty('traceabilities', [Traceability], true)
  traceabilities: Array<Traceability> = [];

  @JsonProperty('haulerEmployeeId', Number, true)
  haulerEmployeeId: number = undefined;

  @JsonProperty('payments', [Payment], true)
  payments: Array<Payment> = [];

  // Marker that the local order may differ from the server version.
  @JsonProperty('local', Boolean, true)
  local: boolean = undefined;

  // Attention, cette propriété sert à déterminer si la commande a été confirmée par le serveur.
  // Affecte si une transition est possible (disable le bouton)
  @JsonProperty('blocked', Boolean, true)
  blocked: boolean = undefined;

  @JsonProperty('sequence', Number, true)
  sequence: number = undefined;

  @JsonProperty('routeId', Number, true)
  routeId: number = undefined;

  @JsonProperty('nextSequenceNumber', Number, true)
  nextSequenceNumber: number = undefined;

  @JsonProperty('deliveryRecipient', DeliveryRecipient, true)
  deliveryRecipient: DeliveryRecipient = undefined;

  @JsonProperty('manifest', OrderManifest, true)
  manifest: OrderManifest = undefined;

  @JsonProperty('externalReference', String, true)
  externalReference: string = undefined;

  @JsonProperty("lockerData", LockerData, true)
  lockerData: LockerData = undefined;

  public uuid;

  constructor(id?: number) {
    this.id = id;
  }

  isPickup(): boolean {
    return this.stateMachineWorkflow === 'PICKUP';
  }

  public compareByLastnameFallbackFirstNameAndId(orderB: Order): number {
    let sortResult = this.client.lastName.localeCompare(orderB.client.lastName);
    if (sortResult === 0) {
      sortResult = this.client.firstName.localeCompare(orderB.client.firstName);
    }
    // eslint-disable-next-line radix
    return sortResult === 0 ? parseInt(this.id) - parseInt(orderB.id) : sortResult;
  }

  update(orderMessage: OrderMessage) {
    this.deliveryDate = moment(orderMessage.date);
    this.status = orderMessage.status;
    this.haulerEmployeeId = orderMessage.actorId;
  }
}
