<ion-list>
  <ion-radio-group [value]="selection" (ionChange)="filterChanged($any($event).detail)">
    <ion-list-header>
      <span translate="pages.map.filter.label"></span>
    </ion-list-header>

    <ion-item>
      <fa-layers size="lg" slot="start">
        <fa-icon [icon]="['fas', 'map-marker']" class="ON_THE_WAY_TO_CUSTOMER" transform="right-5"></fa-icon>
        <fa-icon [icon]="['fas', 'map-marker']" class="READY_FOR_HAULAGE"></fa-icon>
      </fa-layers>
      <ion-label><span translate="pages.map.filter.all"></span></ion-label>
      <ion-radio value="ALL" slot="end"></ion-radio>
    </ion-item>

    <ion-item>
      <fa-layers class="READY_FOR_HAULAGE" size="lg" slot="start">
        <fa-icon [icon]="['fas', 'map-marker']" [fixedWidth]="true"></fa-icon>
        <fa-icon [inverse]="true" [icon]="['fas', 'user']" transform="shrink-10 up-3" [fixedWidth]="true"></fa-icon>
      </fa-layers>
      <ion-label><span translate="workflows.status.ready_for_haulage"></span></ion-label>
      <ion-radio value="READY_FOR_HAULAGE" slot="end"></ion-radio>
    </ion-item>

    <ion-item>
      <fa-layers class="ON_THE_WAY_TO_CUSTOMER" slot="start">
        <fa-icon [icon]="['fas', 'map-marker']" [fixedWidth]="true"></fa-icon>
        <fa-layers-text content="X" [fixedWidth]="true"
                        style="font-weight: bold; color: whitesmoke"
                        transform="shrink-10 up-2 right-2"></fa-layers-text>
      </fa-layers>
      <ion-label><span translate="workflows.status.on_the_way_to_customer"></span></ion-label>
      <ion-radio value="ON_THE_WAY_TO_CUSTOMER" slot="end"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
