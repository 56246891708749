<ion-header>
  <ion-toolbar>
    <!-- On garde le look bouton parce que c'est vraiment une action ici -->
    <ion-button [disabled]="isOrderButtonDisabled" (click)="reorder()" color="success" slot="start">
      <fa-icon [icon]="['fas', 'check']" size="2x"></fa-icon>
    </ion-button>

    <ion-title><span translate="pages.reorganization.title"></span></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="cancel()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-item>
    <fa-icon [icon]="['fal', 'crosshairs']" [ngClass]="isCurrentPositionFound ? '' : 'animated faa-burst'"
             slot="start"></fa-icon>
    <ion-label>
      <span translate="pages.reorganization.positionSelect"></span></ion-label>
    <ion-select [legacy]="true" [(ngModel)]="reorderStartPoint" (ionChange)="initialPositionSelect()" slot="end"
                [cancelText]="'actions.cancel' | translate ">
      <ion-select-option value="-1">
        <span translate="pages.reorganization.currentPosition"></span></ion-select-option>
      <ng-container *ngFor="let provider of providers">
        <ion-select-option [value]="'p_' + provider.id"
                           *ngIf="provider.selected && provider.visible">{{ provider.name }}</ion-select-option>
      </ng-container>
      <ng-container *ngFor="let location of userLocations">
        <ion-select-option [value]="'l_' + location.position.id">{{ location.name }}</ion-select-option>
      </ng-container>
    </ion-select>
  </ion-item>

  <ion-item-group>
    <ion-list-header>
      <span translate="optimizeOrders.options"></span>
    </ion-list-header>

    <ion-item class="date-time-container" button="true" id="open-start-time-picker">
      <fa-icon [icon]="['far', 'clock']" slot="start"></fa-icon>
      <ion-label>
        <span translate="optimizeOrders.startTime"></span>
      </ion-label>
      <ion-text slot="end">
        {{ startTime | Time: settings.hourFormat }}
      </ion-text>
      <ion-modal trigger="open-start-time-picker" #modalDesiredArrivalTimeHourEnd class="datetime-modal time-only-picker">
        <ng-template>
          <ion-content force-overscroll="false" show-backdrop="true">
            <ion-datetime
              #startTimeControl [value]="startTime" [hourCycle]="settings.hourFormat === TimeFormatEnum.STANDARD ? 'h12': 'h23'"
              presentation="time" show-default-buttons="true" [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
              [multiple]="false" (ionChange)="onStartTimeChanged(startTimeControl.value)">
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ion-item>

    <ion-item>
      <fa-icon [icon]="['fas', 'alarm-exclamation']" size="1x" slot="start"></fa-icon>
      <ion-label><span translate="pages.reorganization.timeConstraint"></span></ion-label>
      <ion-toggle [legacy]="true" [(ngModel)]="withTimeConstraints"></ion-toggle>
    </ion-item>
  </ion-item-group>

  <ion-item-group *ngIf="(!allOrders || allOrders.length < 1)"
                  class="italic text-muted ion-padding">
    <ion-item lines="no">
      <ion-label class="text-center">{{ 'messages.empty_list' | translate }}</ion-label>
    </ion-item>
  </ion-item-group>
  <ion-list>
    <ion-list-header>
      <span translate="pages.reorganization.group"></span>
    </ion-list-header>
    <ion-reorder-group (ionItemReorder)="reorderItems($event)" disabled="false">

      <ion-item *ngFor="let orderView of allOrders; let i=index" class="item-no-padding ion-text-wrap"
                [hidden]="orderView.hidden">
        <ion-reorder slot="end"></ion-reorder>
        <ion-grid>
          <ion-row>
            <!--<ion-col class="empty-sequence" col-1>-->
            <!--{{i+1}}.-->
            <!--</ion-col>-->
            <ion-col size="1">
              <fa-layers [fixedWidth]="true" size="lg" *ngIf="orderView.order.sequence">
                <fa-icon [icon]="['far', 'circle']"></fa-icon>
                <fa-layers-text content="{{i + 1}}" transform="shrink-4" class="number"></fa-layers-text>
              </fa-layers>

              <fa-layers *ngIf="!orderView.order.sequence || orderView.order.sequence !== (i+1)" class="arrow-sequence"
                         size="lg">
                <fa-icon [icon]="['far', 'long-arrow-alt-up']"></fa-icon>
              </fa-layers>

              <fa-layers *ngIf="!orderView.order.sequence || orderView.order.sequence !== (i+1)" class="old-sequence"
                         size="lg">
                <fa-icon [icon]="['far', 'circle']"></fa-icon>
                <fa-layers-text content="{{orderView.order.sequence || (i+1)}}" transform="shrink-4"
                                class="number"></fa-layers-text>
              </fa-layers>
            </ion-col>

            <ion-col size="11">
              <mobilus-order-item [orderView]="orderView" [reference]="timeReference"
                          [withDistance]="withDistance"></mobilus-order-item>
            </ion-col>
          </ion-row>

        </ion-grid>
      </ion-item>
    </ion-reorder-group>
  </ion-list>
</ion-content>
