<ion-content class="ion-padding" [ngClass]="{while_drawing:isDrawing}" no-bounce>
    <signature-pad #signaturePad (drawStart)="drawStart()" (drawEnd)="drawEnd()"></signature-pad>
</ion-content>

<ion-footer>
  <ion-list>
    <ion-item class="ion-text-center">
      <ion-checkbox [legacy]="true" [(ngModel)]="confirmation"></ion-checkbox>
      <ion-label class="mbls-text-nowrap"
                 (click)="confirmation = !confirmation">{{ 'signature.confirm.text' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <ion-grid class="ion-text-center">
    <ion-row>
      <ion-col size="4" style="padding: 0;">
        <ion-button color="danger" (click)="drawCancel()" style="margin: 0">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
          {{ 'actions.cancel' | translate }}
        </ion-button>
      </ion-col>
      <ion-col size="4" style="padding: 0;">
        <ion-button color="light" (click)="drawClear()" style="margin: 0; width: 90%;">
          <fa-icon [icon]="['fas', 'undo']"></fa-icon>
          {{ 'actions.erase' | translate }}
        </ion-button>
      </ion-col>
      <ion-col size="4" style="padding: 0;">
        <ion-button color="success" (click)="drawComplete()"
                    [disabled]="!confirmation || !didDraw" style="margin: 0">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
          {{ 'actions.accept' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
