<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button>
        <img src="assets/images/logo_menu.png"/>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>{{ 'pages.report-forms.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item>
    <ion-label><span translate="pages.report-forms.report_type"></span></ion-label>
    <ion-select [legacy]="true" [(ngModel)]="reportTypeActive">
      <ion-select-option [value]="report"
                         *ngFor="let report of reportTypes">{{'pages.report-forms.' + report + '.title' |
        translate}}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <div id="form">
    <ion-item class="date-time-container" button="true" id="open-date-picker" [disabled]="isDateTimeDisabled">
      <ion-label>
        <span translate="pages.report-forms.report_date"></span>
      </ion-label>
      <ion-text slot="end">{{ reportRequest.startDate | DatePipe: null:userSettings.dateFormat:DateTimeCombinationEnum.dateOnly }}</ion-text>
      <ion-modal trigger="open-date-picker" #modalReportDatetime class="datetime-modal">
        <ng-template>
        <ion-content force-overscroll="false" show-backdrop="true">
          <ion-datetime
            #reportDatetime show-default-buttons="true" [value]="reportRequest.startDate" [locale]="userSettings.getLocate()" presentation="date"
            [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
            [multiple]="false" (ionChange)="onDateChange(reportDatetime.value)">
          </ion-datetime>
        </ion-content>
      </ng-template>
      </ion-modal>
    </ion-item>
    <div *ngIf="reportTypeActive==='trips-reports'">
      <ion-item>
        <ion-label class="form-label"> {{translateDistanceText('pages.trips-reports.extra_distance_km')}}</ion-label>
        <ion-input [legacy]="true" type="number" [(ngModel)]="reportRequest.extraForDistanceAbove"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label
          class="ion-text-wrap"> {{'pages.report-forms.trips-reports.excludeAddressCopies' | translate}}</ion-label>
        <ion-checkbox [legacy]="true" slot="start" [(ngModel)]="reportRequest.excludeAppNumber"></ion-checkbox>
      </ion-item>
    </div>
    <ion-label class="form-label">{{'pages.report-forms.drugstores' | translate}}</ion-label>
    <ion-item lines="none" *ngFor="let provider of reportRequest.providers">
      <ion-label>{{provider.label}}</ion-label>
      <ion-checkbox [legacy]="true" slot="start" [(ngModel)]="provider.checked"></ion-checkbox>
    </ion-item>
    <div *ngIf="user?.isHaulerEmployeeManager()">
      <ion-label class="form-label"
                 *ngIf="reportRequest.haulerEmployees.length">{{'pages.report-forms.haulers' | translate}}</ion-label>
      <ion-item lines="none" *ngFor="let employee of reportRequest.haulerEmployees">
        <ion-label>{{employee.label}}</ion-label>
        <ion-checkbox [legacy]="true" slot="start" [(ngModel)]="employee.checked"></ion-checkbox>
      </ion-item>
      <ion-item lines="none">
        <ion-label>{{'pages.report-forms.all' | translate}}</ion-label>
        <ion-checkbox [legacy]="true" slot="start" (ionChange)="manageCourierCheckboxes($any($event).detail.checked)"
                      [(ngModel)]="allCourierChecked"></ion-checkbox>
      </ion-item>
      <ion-item lines="none">
        <ion-label>{{'pages.report-forms.none' | translate}}</ion-label>
        <ion-checkbox [legacy]="true" slot="start" (ionChange)="manageCourierCheckboxes(!$any($event).detail.checked)"
                      [disabled]="!oneCourierChecked" [(ngModel)]="noneChecked"></ion-checkbox>
      </ion-item>
    </div>
    <div>
      <ion-label class="form-label">{{'pages.report-forms.extras' | translate}}</ion-label>
      <ion-item lines="none">
        <ion-label>{{'pages.report-forms.includeTagsReport' | translate}}</ion-label>
        <ion-checkbox [legacy]="true" slot="start" [(ngModel)]="includeTagsReport"></ion-checkbox>
      </ion-item>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button [disabled]="!reportRequest.hasActiveProviders() || !reportRequest.hasActiveHaulerEmployees()"
                expand="full" class="button-block" (click)="onGenerateReport()" color="success">{{
      'pages.report-forms.button' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
