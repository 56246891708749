<ion-header>

  <ion-toolbar>
    <ion-title (click)="close()">{{ 'optimizeOrders.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" class="text-xxlarge">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-card *ngIf="routeSummary" (click)="openRouteSummary()">
    <ion-item lines="none">
      {{'routeSummary.summary' | translate: {
      travelTime: routeSummary.totalTravelTimeMin | duration:'minutes',
      distance: routeSummary.totalDistanceKm | Distance:settings.distanceUnit:true:true:'1.1-1'
    } }}
      <fa-icon slot="end" [icon]="['fas','info-square']"></fa-icon>
    </ion-item>
  </ion-card>

  <p class="current-position-not-found-message"
     *ngIf="!isCurrentPositionFound && locationOrigin==='0' && readyForCurrentLocation">
    {{'messages.errors.current_position_not_found' | translate}}</p>
  <p class="gps-deactivated-message"
     *ngIf="!readyForCurrentLocation">
    {{'messages.errors.gps-deactivated' | translate}}</p>

  <ion-list>
    <ion-radio-group [(ngModel)]="locationOrigin">
      <ion-list-header>
        <span translate="optimizeOrders.firstStop"></span>
      </ion-list-header>

      <ion-item>
        <fa-icon [icon]="readyForCurrentLocation ? ['fas', 'crosshairs'] : ['fas', 'exclamation-triangle']" size="1x"
                 slot="start"
                 [ngClass]="(!isCurrentPositionFound && readyForCurrentLocation) ? 'animated faa-burst' : (!readyForCurrentLocation) ? 'diag_unavailable' : ''"></fa-icon>
        <ion-label>
          <span translate="optimizeOrders.currentLocation"></span>
        </ion-label>
        <ion-radio value="0" [disabled]="!readyForCurrentLocation || !isCurrentPositionFound" slot="end"></ion-radio>
      </ion-item>

      <ng-container *ngFor="let provider of providers">
        <ion-item *ngIf="provider.selected && provider.visible">
          <fa-icon [icon]="['fas', 'building']" size="1x" slot="start"></fa-icon>
          <ion-label>{{ provider.name }}</ion-label>
          <ion-radio [value]="'p_' + provider.id" slot="end"></ion-radio>
        </ion-item>
      </ng-container>

      <ion-item *ngFor="let location of userLocations">
        <fa-icon [icon]="['fas', 'map-marker-edit']" size="1x" slot="start"></fa-icon>
        <ion-label>{{ location.name }}</ion-label>
        <ion-radio [value]="'l_' + location.position.id" slot="end"></ion-radio>
      </ion-item>

      <ion-item lines="none">
        <ion-button fill="outline" (click)="addLocation('start')" slot="end"><span
          translate="location-settings.addLocation"></span></ion-button>
      </ion-item>
    </ion-radio-group>

    <ion-item-group>
      <ion-list-header>
        <span translate="optimizeOrders.options"></span>
      </ion-list-header>

      <ion-item class="date-time-container" button="true" id="open-start-time-picker">
        <fa-icon [icon]="['fas', 'clock']" size="1x" slot="start"></fa-icon>
        <ion-label>
          <span translate="optimizeOrders.startTime"></span>
        </ion-label>
        <ion-text slot="end">
          {{ startTime | Time: settings.hourFormat }}
        </ion-text>
        <ion-modal trigger="open-start-time-picker" #modalDesiredArrivalTimeHourEnd class="datetime-modal time-only-picker">
          <ng-template>
            <ion-content force-overscroll="false" show-backdrop="true">
              <ion-datetime
                #startTimeControl [value]="startTime" [hourCycle]="settings.hourFormat === TimeFormatEnum.STANDARD ? 'h12': 'h23'"
                presentation="time" show-default-buttons="true" [cancelText]="'actions.cancel' | translate" [doneText]="'actions.accept' | translate"
                [multiple]="false" (ionChange)="onStartTimeChanged(startTimeControl.value)">
              </ion-datetime>
            </ion-content>
          </ng-template>
        </ion-modal>
      </ion-item>

      <ion-item>
        <fa-icon [icon]="['fas', 'alarm-exclamation']" size="1x" slot="start"></fa-icon>
        <ion-label class="ion-text-wrap"><span translate="pages.reorganization.timeConstraint"></span></ion-label>
        <ion-toggle [legacy]="true" [(ngModel)]="withTimeConstraints" slot="end"></ion-toggle>
      </ion-item>
    </ion-item-group>

    <ion-radio-group [(ngModel)]="locationEnd">
      <ion-list-header>
        <span translate="optimizeOrders.endPosition"></span>
      </ion-list-header>

      <ion-item>
        <fa-icon [icon]="['fas', 'location-slash']" size="1x" slot="start"></fa-icon>
        <ion-label><span translate="optimizeOrders.noLocation"></span></ion-label>
        <ion-radio value="false" slot="end"></ion-radio>
      </ion-item>

      <ion-item>
        <fa-icon [icon]="readyForCurrentLocation ? ['fas', 'crosshairs'] : ['fas', 'exclamation-triangle']" size="1x"
                 slot="start"
                 [ngClass]="(!isCurrentPositionFound && readyForCurrentLocation) ? 'animated faa-burst' : (!readyForCurrentLocation) ? 'diag_unavailable' : ''"></fa-icon>
        <ion-label>
          <span translate="optimizeOrders.currentLocation"></span>
        </ion-label>
        <ion-radio value="0" [disabled]="!readyForCurrentLocation || !isCurrentPositionFound" slot="end"></ion-radio>
      </ion-item>

      <ng-container *ngFor="let provider of providers">
        <ion-item *ngIf="provider.selected && provider.visible">
          <fa-icon [icon]="['fas', 'building']" size="1x" slot="start"></fa-icon>
          <ion-label>{{ provider.name }}</ion-label>
          <ion-radio [value]="'p_' + provider.id" slot="end"></ion-radio>
        </ion-item>
      </ng-container>

      <ion-item *ngFor="let location of userLocations">
        <fa-icon [icon]="['fas', 'map-marker-edit']" size="1x" slot="start"></fa-icon>
        <ion-label>{{ location.name }}</ion-label>
        <ion-radio [value]="'l_' + location.position.id" slot="end"></ion-radio>
      </ion-item>

      <ion-item lines="none">
        <ion-button fill="outline" (click)="addLocation('end')" slot="end"><span
          translate="location-settings.addLocation"></span></ion-button>
      </ion-item>
    </ion-radio-group>

  </ion-list>
</ion-content>

<ion-footer class="actions-footer">
  <ion-grid class="ion-no-margin ion-no-padding">
    <ion-row>
      <ion-col
        size="6"
        class="transitions-buttons-col">
        <ion-button (click)="close()"
                    expand="full"
                    size="large"
                    class="transition-button"
                    fill="solid"
                    color="secondary">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
          {{ 'actions.cancel' | translate }}
        </ion-button>
      </ion-col>

      <ion-col
        size="6"
        class="transitions-buttons-col">
        <ion-button
          expand="full"
          size="large"
          fill="solid"
          class="transition-button"
          [disabled]="(!isCurrentPositionFound && locationOrigin==='0') || locationOrigin==='-1'"
          (click)="optimize()"
          color="success">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
          {{ 'optimizeOrders.optimize' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
