import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Picture} from '@models/business/transition-data.model';
import { CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import {BackgroundGeolocationService} from '@services/background-geolocation/background-geolocation.service';
import * as moment from 'moment';
import * as watermark from 'watermarkjs';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

@Injectable({
  providedIn: 'root'
})
export class PictureService {

  constructor(private sanitizer: DomSanitizer,
              private backgroundGeolocationService: BackgroundGeolocationService,
              private capacitorPlugins: CapacitorPlugins
  ) {
  }

  takePicture(prefix: string): Promise<Picture | void> {
    const picture = new Picture();

    picture.mimeType = 'image/jpeg';
    picture.extension = 'jpg';

    const options: ImageOptions = {
      quality: 40,
      saveToGallery: false,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      width: 1024,
      height: 1024
    };

    const promise = new Promise<Picture | void>((resolve, reject) => {
      this.capacitorPlugins.getCameraPlugin().getPhoto(options).then((image) => {
        picture.base64String = image.base64String;
        const dataUrl = image.dataUrl;
        this.watermarkImage(dataUrl)
          .then(watermarked => {
            picture.base64String = watermarked.split(',', 2)[1];
            picture.safeUrl = this.sanitizer.bypassSecurityTrustUrl(watermarked);
            resolve(picture);
          });
      }, (err) => {
        console.log('rejecting on error', err);
        reject(err);
      });

    });
    return promise;
  }

  private watermarkImage(dataUrl: string) {
    const opacity = 0.8;
    const position = this.backgroundGeolocationService.getLastKnownPosition();
    const now = moment().format();
    let caption = now;
    if (position) {
      caption += ` @ (${position.latitude}, ${position.longitude})`;
      if (position.accuracy) {
        caption += ` ± ${position.accuracy}m`;
      }
    }

    return fetch(dataUrl)
      .then(res => res.blob())
      .then(blob => {
        return watermark([blob], {type: 'image/jpeg'})
          .dataUrl(watermark.text.lowerLeft(caption, '20px Arial', '#000', opacity))
          .then(value => {
            return value;
          });
      });
  }
}
