import {Component} from '@angular/core';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {AdminPage} from '@app/pages/admin/admin.page';
import {BugReportPage} from '@app/pages/bug-report/bug-report.page';
import { PluginListenerHandle } from '@capacitor/core';
import {AlertController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {OverlayEventDetail} from '@ionic/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {AwaitingTransitionStoreService} from '@services/awaiting-transition/awaiting-transition-store.service';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {DeviceInfoService} from '@services/device-info/device-info.service';
import {endpoints, EndpointService} from '@services/endpoint/endpoint.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {IonicDeployStatus} from '@services/ionic-deploy/ionic-deploy-status';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {satisfies} from 'semver';
import {SamplePage} from '@app/components/sample/sample.page';
import { AppEvents } from '@services/app-events/app-events';
import { CapacitorPlugins } from '@app/services/capacitor-plugins/capacitor-plugins';

const CONFIG = {
  GA: {
    PAGE_NAME: 'DiagnosticPage'
  }
};

@Component({
  templateUrl: './diagnostic.page.html',
  styleUrls: ['./diagnostic.page.scss'],
})
export class DiagnosticPage {

  private unsubscribe: Subject<void> = new Subject<void>();
  private networkHandler: PluginListenerHandle;
  public withCloseButton = false;
  private tapCount = 0;
  private timeoutFunction = false;
  haulerCode: string;

  public isCordova: boolean;
  public endpointHint: string;
  private connectionStatus: ConnectionStatus;
  isIos = false;
  isAndroid = false;
  osVersion: string;
  supportedOsVersion = true;
  private isPluginCapableDevice = false;
  network: any = {type: 'unknown', isOnline: false};

  deployStatus: IonicDeployStatus;

  SUPPORTED_OS = {
    android: '>=5.0.0',
    ios: '>=10.3.0'
  };

  constructor(private platform: Platform,
              private modalCtrl: ModalController,
              private navParams: NavParams,
              public ionicDeploy: IonicDeploy,
              public deviceInfo: DeviceInfo,
              private deviceInfoService: DeviceInfoService,
              private endpointService: EndpointService,
              private log: LogService,
              private alertCtrl: AlertController,
              private translateService: TranslateService,
              private connectionStatusService: ConnectionStatusService,
              private ga: MblsAnalyticsService,
              private orderStoreService: OrderStoreService,
              private awaitingTransitionStoreService: AwaitingTransitionStoreService,
              private authenticationService: AuthenticationService,
              private mobileContextService: MobileContextService,
              private navController: NavController,
              private appEvents: AppEvents,
              private capacitorPlugins: CapacitorPlugins
  ) {
    if (this.navParams && this.navParams.data) {
      this.withCloseButton = this.navParams.data.withCloseButton;
    }
    this.platform.ready()
      .then(() => {
        this.isCordova = this.platform.is('cordova');
        this.isIos = this.platform.is('ios');
        this.isAndroid = this.platform.is('android');
        this.isPluginCapableDevice = this.platform.is('cordova') || this.platform.is('mobileweb');
        this.osVersion = this.deviceInfo.osVersion;
        try {
          if (this.osVersion && this.isAndroid) {
            if (this.osVersion.length < 5) {
              this.osVersion = this.standardizeVersionNumber(this.osVersion);
            }
            this.supportedOsVersion = satisfies(this.osVersion, this.SUPPORTED_OS.android);
          } else if (this.osVersion && this.isIos) {
            if (this.osVersion.length < 5) {
              this.osVersion = this.standardizeVersionNumber(this.osVersion);
            }
            this.supportedOsVersion = satisfies(this.osVersion, this.SUPPORTED_OS.ios);
          }
        } catch (error) {
          this.log.debug('Os version couldn\'t be verified', this.osVersion, this.supportedOsVersion);
        }
        this.checkEverything();
      });

    this.ionicDeploy.observableStatus.subscribe((status: IonicDeployStatus) => this.deployStatus = status);
    this.connectionStatusService.connectionStatusSubscription.subscribe(next => this.connectionStatus = next);
    this.endpointService.observableEndpoint.subscribe((url: string) => {
      if (url && url !== endpoints.production) {
        this.endpointHint = url;
      } else {
        this.endpointHint = null;
      }
    });
  }

  ionViewWillEnter() {
    this.ionicDeploy.observableStatus
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((status: IonicDeployStatus) => this.deployStatus = status);

    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(next => this.connectionStatus = next);

    this.endpointService.observableEndpoint
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((url: string) => {
        if (url && url !== endpoints.production) {
          this.endpointHint = url;
        } else {
          this.endpointHint = null;
        }
      });

    this.mobileContextService.haulerObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(hauler => {
        if (hauler && hauler.code) {
          this.haulerCode = hauler.code;
        } else {
          this.haulerCode = null;
        }
      });
  }

  ionViewDidLeave() {
    this.unsubscribe.next();
    if (this.networkHandler) {
      this.networkHandler.remove();
    }
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  ionViewDidEnter() {
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }

  private standardizeVersionNumber(version: string): string {
    while (version.length < 5) {
      version = version.concat('.0');
    }
    return version;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async tap() {
    this.tapCount++;
    if (this.tapCount > 5) {
      const modal = await this.modalCtrl.create({
        component: AdminPage
      });
      await modal.present();
    }
    if (!this.timeoutFunction) {
      this.timeoutFunction = true;
      setTimeout(() => {
        this.tapCount = 0;
        this.timeoutFunction = false;
      }, 5000);
    }
  }

  checkEverything() {
    if (this.isPluginCapableDevice) {
      this.checkNetwork();
      if (this.appEvents.isUserAuthorized() === true) {
        this.deviceInfoService.updatePermissions();
      } else {
        this.appEvents.authorizationSuccessAnnounced$
          .pipe(
            filter((val) => val != null),
            take(1)
          )
          .subscribe(() => {
            this.deviceInfoService.updatePermissions();
          });
      }
    }
  }

  checkNetwork() {
    this.capacitorPlugins.getNetworkPlugin().getStatus().then(status => {
      this.network.type = status.connectionType;
      this.network.isOnline = status.connected;
    });
    this.networkHandler = this.capacitorPlugins.getNetworkPlugin().addListener('networkStatusChange', (status) => {
      this.network.type = status.connectionType;
      this.network.isOnline = status.connected;
    });
  }

  async requestLocationPermissions() {
    if (this.isIos) {
      const alert = await this.alertCtrl.create(
        {
          header: this.translateService.instant('diagnostic.location.iosLocationSettingAlert'),
          message: this.translateService.instant('diagnostic.location.iosLocationSetting'),
          buttons: [this.translateService.instant('diagnostic.location.iosLocationSettingConfirmation')]
        }
      );
      await alert.present();
    } else {
      this.capacitorPlugins.getGeolocationPlugin().requestPermissions().then(
        _value => {
          this.checkEverything();
        }, error => this.log.error('Unable to request location permission', error));
    }
  }

  async requestCameraPermissions() {
    if (this.isIos) {
      const alert = await this.alertCtrl.create(
        {
          header: this.translateService.instant('diagnostic.camera.iosCameraSettingAlert'),
          message: this.translateService.instant('diagnostic.camera.iosCameraSetting'),
          buttons: [this.translateService.instant('diagnostic.camera.iosCameraSettingConfirmation')]
        }
      );
      alert.present();
    } else {
      this.capacitorPlugins.getCameraPlugin().requestPermissions().then(
        value => {
          this.checkEverything();
        }, error => this.log.error('Unable to request camera permission', error));
    }
  }

  installNewVersion() {
    this.ionicDeploy.installNewVersion();
  }

  checkForUpdate() {
    this.ionicDeploy.check();
  }

  async resetLocalChannel() {
    this.log.info('local channel reset requested');
    const prompt = await this.alertCtrl.create({
      header: this.translateService.instant('deploy.localChannelReset.title'),
      message: this.translateService.instant('deploy.localChannelReset.message'),
      buttons: [
        {
          text: this.translateService.instant('actions.cancel'),
          handler: data => {
          }
        },
        {
          text: this.translateService.instant('actions.ok'),
          handler: data => {
            this.ionicDeploy.resetLocalChannel();
          }
        }
      ]
    });
    await prompt.present();
  }

  async sendDebugReport() {
    this.log.info('debug report requested');

    const modal = await this.modalCtrl.create({
      component: BugReportPage
    });

    modal.onDidDismiss().then((data: OverlayEventDetail) => {
      if (data && data.data && data.data.confirm) {
        this.dismiss();
      }
    });


    await modal.present();
  }

  killApp() {
    this._killApp();
  }

  private _killApp() {
    // https://geekyminds.co.in/exit-app-on-back-press-ionic-4/
    // ts-ignore:no-string-literal
    navigator['app'].exitApp();
    // this.platform.exitApp();
  }

  async deleteDbs() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('diagnostic.delete-db.alert-title'),
      subHeader: this.translateService.instant('diagnostic.delete-db.alert-body'),
      buttons: [
        {
          text: this.translateService.instant('actions.cancel'),
          role: 'cancel',
          handler: () => {
          }
        }, {
          text: this.translateService.instant('diagnostic.delete-db.action'),
          handler: () => {
            this.orderStoreService.destroyDatabase();
            this.awaitingTransitionStoreService.destroyDatabase();
            this.authenticationService.doLogout();
            this.dismiss();
            this.navController.navigateRoot('/login');
          }
        }
      ]
    });
    await alert.present();
  }

  async openSample() {
    const modal = await this.modalCtrl.create({
      component: SamplePage
    });
    await modal.present();
  }

}
