<ion-header>
  <ion-toolbar>
    <ion-title (click)="close()"><span translate="options.title"></span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label><span translate="options.search.label"></span></ion-label>
      <ion-toggle [legacy]="true" [(ngModel)]="searching" (ionChange)="searchToggle()"></ion-toggle>
    </ion-item>

    <ion-item>
      <ion-label><span translate="options.filter.withTimeConstraint"></span></ion-label>
      <ion-toggle [legacy]="true" [(ngModel)]="statusSettings.activeFilter.withTimeConstraint"
                  (ionChange)="timeConstraintFilterChanged()"></ion-toggle>
    </ion-item>

    <ion-item *ngFor="let tag of filterableTags">
      <ion-label>
        <mobilus-tag [tag]="tag" [short]="false"></mobilus-tag>
      </ion-label>
      <ion-toggle [legacy]="true" [checked]="selectedTag(tag.label)"
                  (ionChange)="tagFilterChanged(tag.label, $any($event).detail.checked)"></ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>
