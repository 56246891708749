<ion-app>
  <!--  <ion-split-pane>-->
  <ion-menu side="start" menuId="MAIN_MENU" contentId="MAIN_MENU">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title (click)="close()">{{ 'title' | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-avatar style="margin: 8px 16px 8px 0;">
            <img src="assets/images/user.png">
          </ion-avatar>
          <span style="white-space: normal" (click)="openProfile()">{{ userProfile?.firstName }} {{ userProfile?.lastName }}</span>
          <ion-button (click)="logout()" slot="end" fill="clear">
            <fa-icon [icon]="['fas','sign-out']" slot="end"></fa-icon>
          </ion-button>
        </ion-item>
        <ion-item (click)="selectView(ViewSelection.ORDER_LIST)">
          <fa-icon [icon]="['fas','inbox']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.orders"></span>
        </ion-item>
        <ion-item *ngIf="userProfile?.isHaulerEmployeeManager()" (click)="openPage('/hauler-employees')">
          <fa-icon [icon]="['fas','users']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.hauler_employees"></span>
        </ion-item>
        <ion-item (click)="openPage('/order-history')">
          <fa-icon [icon]="['fas', 'history']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.history"></span>
        </ion-item>
        <ion-item (click)="openPage('/reports-forms')">
          <fa-icon [icon]="['fas', 'clipboard-list']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.reports"></span>
        </ion-item>
        <ion-item (click)="openPage('/notification-center')">
          <fa-icon [icon]="['fas', 'bell']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.notification-center"></span>&nbsp;
          <ion-badge color="danger" *ngIf="pendingNotifications">{{ pendingNotifications }}</ion-badge>
        </ion-item>
        <ion-item (click)="openPage('/setting')">
          <fa-icon [icon]="['fas', 'cogs']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.settings"></span>
        </ion-item>
        <ion-item (click)="openAdvanced()" detail-none *ngIf="debugging || onWeb">
          <fa-icon [icon]="['fas', 'users-cog']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.advanced"></span>
        </ion-item>
        <ion-item (click)="openDiagnostic()">
          <fa-icon [icon]="['fas', 'check']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.diagnostic"></span>
        </ion-item>
        <ion-item (click)="openHelp()">
          <fa-icon [icon]="['fas', 'question']" slot="end"></fa-icon>
          <span translate="pages.drawer-menu.help"></span></ion-item>
        <ion-item (click)="forceSync()">
          <fa-icon [icon]="['fas', 'cloud-download']" slot="end"></fa-icon>
          <span translate="options.actions.forceGet"></span>
        </ion-item>
        <ion-item (click)="installNewVersion()" detail-none
                  *ngIf="deployStatus.newVersionAvailable">
          <fa-layers class="update-cloud" slot="end">
            <fa-icon [icon]="['fas', 'download']" size="sm"
                     transform="up-8"></fa-icon>
          </fa-layers>
          <span translate="deploy.newVersionInstallLong"></span>
        </ion-item>
        <ion-item (click)="logout()">
          <fa-icon [icon]="['fas', 'sign-out']" slot="end"></fa-icon>
          <span translate="logout.label"></span>
        </ion-item>

        <ion-item (click)="helpConnection()">
          <fa-icon slot="end" [icon]="['fas', 'exclamation-triangle']" class="disconnected"
                   *ngIf="!connectionStatus.isNetworkConnectedForUser"></fa-icon>
          <fa-icon slot="end" [icon]="['fas', 'link']" class="connected"
                   [ngClass]="(!connectionStatus.isMqttConnected || connectionStatus.isMqttReconnecting) ? 'animated faa-burst': ''"
                   *ngIf="connectionStatus.isMqttConnectedForUser"></fa-icon>
          <fa-icon slot="end" [icon]="['fas', 'unlink']" class="disconnected"
                   *ngIf="!connectionStatus.isMqttConnectedForUser"></fa-icon>
          <span
            *ngIf="connectionStatus.isNetworkConnectedForUser && connectionStatus.isMqttConnectedForUser">{{'messages.info.connection_status.title' | translate}}</span>
          <span
            *ngIf="!connectionStatus.isNetworkConnectedForUser || !connectionStatus.isMqttConnectedForUser">{{getConnectionErrorMessage() | translate}}</span>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>

  <ion-menu side="end" contentId="MAIN_MENU" menuId="SORT_AND_FILTER_MENU">
    <ion-content>
      <mobilus-order-list-options-menu></mobilus-order-list-options-menu>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="MAIN_MENU"></ion-router-outlet>
  <!--  </ion-split-pane>-->
</ion-app>
