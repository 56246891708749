import {ViewChild} from '@angular/core';
import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {SignaturePinModalPage} from '@app/pages/signature-pin-modal/signature-pin-modal.page';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {NavParams, Platform} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {SignaturePinEnum} from '@models/settings/signature-pin.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SignaturePadComponent} from '@almothafar/angular-signature-pad';

@Component({
  templateUrl: './signature.page.html',
  styleUrls: ['./signature.page.scss'],
})
export class SignaturePage implements OnInit {

  @ViewChild('signaturePad', {static: true}) public signaturePad: SignaturePadComponent;

  private unsubscribe: Subject<void> = new Subject<void>();
  confirmation = false;
  isDrawing = false;
  didDraw = false;
  title = 'signature.title';
  signaturePinValue: string;
  signaturePinActivated: boolean;
  public enterPin: SignaturePinEnum = SignaturePinEnum.enterPin;

  public signatureImage: string;
  // private modalId: string;
  modal: HTMLIonModalElement;
  private viewLoaded = false;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private mobileContextService: MobileContextService,
              private screenOrientation: ScreenOrientation,
              private platform: Platform,
  ) {
    if (this.navParams.get('title')) {
      this.title = this.navParams.get('title');
    }
    // NOTE: due to the ionic bug the below does not work since ionic v6.0.2
    // ALEX: I applied the fix as suggested by one of the ionic devs - see https://github.com/ionic-team/ionic-framework/issues/24673#issuecomment-1043428024
    // this.modalCtrl.getTop().then(value => {
    //   this.modalId = value.id;
    // });
  }

  ionViewWillEnter() {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userSetting => {
        this.signaturePinValue = userSetting.signaturePinValue;
        this.signaturePinActivated = userSetting.signaturePinActivated;
        this.orientAndLockScreen();
      });

    this.platform.backButton.subscribe(() => {
      this.screenOrientation.unlock();
    });

    this.orientAndLockScreen();
  }

  ionViewDidEnter() {
    this.viewLoaded = true;
    this.canvasResize();
  }

  ionViewDidLeave() {
    this.screenOrientation.unlock();
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  private orientAndLockScreen() {
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE)
      .then(() => {
        this.signaturePad.clear();
        this.canvasResize();
      }, error => {
        this.canvasResize();
      });
  }

  ngOnInit() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.canvasResize();
  }

  drawCancel() {
    // this.modalCtrl.dismiss(null, null, this.modalId);
    this.modal.dismiss(null);
  }

  async drawComplete() {
    if (this.signaturePinActivated) {
      this.screenOrientation.unlock();
      const signaturePinModal = await this.modalCtrl.create(
        {
          component: SignaturePinModalPage,
          componentProps: {type: this.enterPin, signaturePinValue: this.signaturePinValue}
        }
      );
      signaturePinModal.onDidDismiss().then(data => {
        if (data.data.ok) {
          this.signatureImage = this.signaturePad.toDataURL();
          // this.modalCtrl.dismiss({signature: this.signatureImage}, null, this.modalId);
          this.modal.dismiss({signature: this.signatureImage});
        }
      });
      await signaturePinModal.present();
    } else {
      this.signatureImage = this.signaturePad.toDataURL();

      this.resizeDataUrl(this.signatureImage, 568, 200)
        .subscribe(signature => {
          // this.modalCtrl.dismiss({signature: signature}, null, this.modalId);
          this.modal.dismiss({signature: signature});
        });
    }
  }

  private resizeDataUrl(base64, wantedWidth, wantedHeight) {
    return new Observable(subscriber => {
      let canvas = document.createElement('canvas');
      canvas.style.width = wantedWidth.toString() + 'px';
      canvas.style.height = wantedHeight.toString() + 'px';

      // create space to receive image
      const img: any = document.createElement('img');

      img.onload = (() => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

        let dataURL = canvas.toDataURL();
        subscriber.next(dataURL);
      });

      img.src = base64;
    });
  }

  drawClear() {
    this.signaturePad.clear();
    this.didDraw = false;
  }

  drawStart() {
    if (this.viewLoaded) {
      this.isDrawing = true;
      this.didDraw = true;
    }
  }

  drawEnd() {
    this.isDrawing = false;
  }

  canvasResize() {
    const data = this.signaturePad.toData();
    this.signaturePad.redrawCanvas();
    this.signaturePad.fromData(data);
  }

}
