import {Input} from '@angular/core';
import {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {Bag} from '@models/business/bag.model';

@Component({
  selector: 'mobilus-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss'],
})
export class BagsComponent implements OnInit {

  @Input() bags: Array<Bag>;

  constructor() {
  }

  ngOnInit(): void {
    this.bags = this.bags.sort((a: Bag, b: Bag) => {
      if (a.bagType.shortName < b.bagType.shortName) {
        return -1;
      } else if (a.bagType.shortName > b.bagType.shortName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
