import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DriverOwnedInfo} from '@models/business/driver-owned-info.model';
import {Order} from '@models/business/order.model';
import {UserSettings} from '@models/settings/settings.model';
import {Transition} from '@models/workflow/transition.model';
import {DriverNotesModalPage} from '@app/pages/driver-notes-modal/driver-notes-modal.page';
import {ModalController, NavParams} from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MapService} from '@services/map/map.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {TransitionService} from '@services/transition/transition.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import Timer = NodeJS.Timer;

const CONFIG = {
  GA: {
    PAGE_NAME: 'OrderDetailPage',
    EVENT: {
      ORDER_TRANSITION: {
        NAME: 'transition_item',
        ACTION: 'button_pressed'
      }
    }
  }
};


@Component({
  selector: 'mobilus-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject<void>();
  order: Order;
  transitions: Array<Transition>;
  settings: UserSettings = new UserSettings(true);
  reference: moment.Moment = moment();
  private timer: Timer;

  isforHistory = false;
  providerPhoneNumber: string;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private transitionService: TransitionService,
              private mapService: MapService,
              private orderStoreService: OrderStoreService,
              private ga: MblsAnalyticsService,
              private mobileContextService: MobileContextService,
              private log: LogService,
              private sanitizer: DomSanitizer,
  ) {
    const view = this.navParams.get('orderView');
    this.order = view.order;
    this.isforHistory = this.navParams.get('isforHistory') || false;
  }

  ngOnInit(): void {
    this.mobileContextService.haulerObservable
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
      hauler => {
        if (hauler) {
          const provider = hauler.linkedProviders
            .find(p => {
              return p.id.toString() === this.order.tenantId.toString();
            });

          if (provider) {
            this.providerPhoneNumber = provider.phoneNumber;
          }
        }
      });

    this.timer = setInterval(() => {
      this.reference = moment();
    }, 5000);

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.settings = next;
      });
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openInMap() {
    this.mapService.openMap(this.order);
  }

  async editDriverNotes() {
    if (!this.order.client.driverOwnedInfo) {
      this.order.client.driverOwnedInfo = new DriverOwnedInfo();
    }
    const modal = await this.modalCtrl
      .create({
        component: DriverNotesModalPage,
        componentProps: {order: this.order}
      });
    await modal.present();
  }

}
