import {Injectable} from '@angular/core';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {SelectedProvider} from '@models/selected-provider.model';
import {ActiveFilter} from '@models/settings/active-filter.model';
import {MapOptionEnum, MapSearchOptionEnum} from '@models/settings/map-option.enum';
import {UserSettings} from '@models/settings/settings.model';
import {StatusSettings} from '@models/settings/status-settings.model';
import {DateFormatEnum} from '@models/units-and-format/date-format.enum';
import {DistanceSystemEnum} from '@models/units-and-format/distance-system.enum';
import {TimeFormatEnum} from '@models/units-and-format/time-format.enum';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {ColorModeEnum} from '@models/settings/ColorModeEnum.model';
import {ActionButtons} from '@models/settings/action-buttons.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsManager {

  private _currentStatus: OrderStatus = OrderStatus.ON_THE_WAY_TO_CUSTOMER;

  private settings = new UserSettings(true);
  private statusSettings: StatusSettings;

  selectedProvidersHasChanged: boolean = false;

  constructor(private mobileContextService: MobileContextService) {
    this.mobileContextService.userSettingsObservable
      .subscribe(userSettings => {
        this.settings = userSettings;
        this.statusSettings = this.settings.settingsForStatus(this._currentStatus);
      });
  }

  getCurrentSettings(): UserSettings {
    return this.settings;
  }

  getCurrentStatus(): OrderStatus {
    return this._currentStatus;
  }

  setCurrentStatus(value: OrderStatus) {
    this._currentStatus = value;
    if (this.settings) {
      this.statusSettings = this.settings.settingsForStatus(this._currentStatus);
    }
    this.mobileContextService.statusHasChanged(this._currentStatus);
  }

  sortChanged() {
    this.statusSettings.previousSortOrder = this.statusSettings.sortOrder;
    this.mobileContextService.settingsHaveChanged(true);
  }

  filterChanged() {
    this.mobileContextService.settingsHaveChanged(true);
  }

  cancelSearch() {
    this.statusSettings.search.allFields = false;
    this.statusSettings.activeFilter.allFields = '';
    this.filterChanged();
  }

  resetFilters() {
    this.statusSettings.search.allFields = false;
    this.statusSettings.activeFilter = new ActiveFilter();
    this.filterChanged();
  }

  public getLanguage(): string {
    return this.settings.language;
  }
  public setLanguage(lang: string, persistChangeImmediately: boolean) {
    this.settings.language = lang;
    this.mobileContextService.settingsHaveChanged(persistChangeImmediately);
  }

  public setIonicChannel(channel: string) {
    if (this.settings) {
      this.settings.channel = channel;
      this.mobileContextService.settingsHaveChanged(true);
    }
  }

  public setCurrentIonicChannel(channel: string) {
    if (this.settings && this.settings.currentChannel !== channel) {
      this.settings.currentChannel = channel;

      this.mobileContextService.settingsHaveChanged(true);
    }
  }

  public setFirstRun(firstRun: boolean) {
    this.settings.firstRun = firstRun;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public markIntroSeen(intro: string) {
    if (!this.settings.seenIntros.includes(intro)) {
      this.settings.seenIntros.push(intro);
    }
    this.mobileContextService.settingsHaveChanged(true);
  }

  public markIntroUnseen(intro: string) {
    if (this.settings.seenIntros.includes(intro)) {
      const index = this.settings.seenIntros.indexOf(intro);
      if (index > -1) {
        this.settings.seenIntros.splice(index, 1);
      }
    }
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setDisplayType(displayType: boolean) {
    this.settings.displayType = displayType;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setGeotrackingEnabled(geotrackingEnabled: boolean) {
    this.settings.geotrackingEnabled = geotrackingEnabled;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setKeepKeepScreenOn(keepScreenOn: boolean) {
    this.settings.keepScreenOn = keepScreenOn;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setSequenceOnSelect(sequenceOnSelect: boolean) {
    this.settings.sequenceOnSelect = sequenceOnSelect;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setShowAverageDeliveryMoment(showAverageDeliveryMoment: boolean) {
    this.settings.showAverageDeliveryMoment = showAverageDeliveryMoment;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setConfirmReturnOrderBackToStore(confirmReturnOrderBackToStore: boolean) {
    this.settings.confirmReturnOrderBackToStore = confirmReturnOrderBackToStore;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setSignatureLockedOnLandscape(signatureLockedOnLandscape: boolean) {
    this.settings.signatureLockedOnLandscape = signatureLockedOnLandscape;

    this.mobileContextService.settingsHaveChanged(true);
  }

  public setSelectedProviders(selectedProviders: Array<SelectedProvider>) {
    this.settings.selectedProviders = selectedProviders;
    this.selectedProvidersHasChanged = true;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public flipGrouping(status: OrderStatus) {
    if (status !== undefined) {
      const statusSettings: StatusSettings = this.settings.settingsForStatus(status);
      statusSettings.groupByProvider = !statusSettings.groupByProvider;
      this.mobileContextService.settingsHaveChanged(true);
    }
  }

  public buttonsChanged() {
    this.mobileContextService.settingsHaveChanged(true);
  }

  public locationChanged() {
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setDistanceUnit(unit: DistanceSystemEnum, submitContext: boolean = true) {
    this.settings.distanceUnit = unit;
    this.mobileContextService.settingsHaveChanged(submitContext);
  }

  public setHourFormat(format: TimeFormatEnum, submitContext: boolean = true) {
    this.settings.hourFormat = format;
    this.mobileContextService.settingsHaveChanged(submitContext);
  }

  public setDateFormat(format: DateFormatEnum, submitContext: boolean = true) {
    this.settings.dateFormat = format;
    this.mobileContextService.settingsHaveChanged(submitContext);
  }

  public setMapOption(map: MapOptionEnum) {
    this.settings.mapOption = map;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setMapQueryOption(map: MapSearchOptionEnum) {
    this.settings.mapQueryOption = map;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setColorMode(colorMode: ColorModeEnum) {
    this.settings.colorMode = colorMode;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public signaturePinChanged(changed: boolean, pinValue: string) {
    this.settings.signaturePinActivated = changed;
    this.settings.signaturePinValue = pinValue;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setSignaturePinValue(signaturePinValue: string) {
    this.settings.signaturePinValue = signaturePinValue;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setOrderReferenceDisplay(orderReferenceDisplay: boolean) {
    this.settings.displayOrderReferenceOnOrderList = orderReferenceDisplay;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public displayProviderFooterSettingChanged(value: boolean) {
    this.settings.displayProviderFooter = value;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public displayDelayCounterSettingChanged(value: boolean) {
    this.settings.displayDelayCounter = value;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public actionButtonsSettingChanged(value: ActionButtons) {
    this.settings.buttons = value;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setHideLocationDisclosure(value: boolean) {
    this.settings.hideLocationDisclosure = value;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setDontAskForBiometric(value: boolean) {
    this.settings.hideBiometricOptInPopup = value;
    this.mobileContextService.settingsHaveChanged(true);
  }

  public setGeotrackingConsentPopupHasBeenSeen(value: boolean) {
    this.settings.hideLocationDisclosure = value;
    this.mobileContextService.settingsHaveChanged(false);
  }
}
