import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from '@app/guards/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'status-tabs',
    pathMatch: 'prefix'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'accounting-reports',
    loadChildren: () => import('./pages/accounting-reports/accounting-reports.module').then(m => m.AccountingReportsPageModule),
  },
  {
    path: 'bug-report',
    loadChildren: () => import('./pages/bug-report/bug-report.module').then(m => m.BugReportPageModule)
  },
  {
    path: 'connection-lost-detail',
    loadChildren: () => import('./pages/connection-lost-detail/connection-lost-detail.module').then(m => m.ConnectionLostDetailPageModule),
  },
  {
    path: 'status-tabs',
    loadChildren: () => import('./pages/status-tabs/status-tabs.module').then(m => m.StatusTabsModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'hauler-employees',
    loadChildren: () => import('./pages/hauler-employees/hauler-employees.module').then(m => m.HaulerEmployeesPageModule),
  },
  {
    path: 'manifest-confirmation',
    loadChildren: () => import('./pages/manifest-confirmation/manifest-confirmation.module').then(m => m.ManifestConfirmationPageModule),
  },
  {
    path: 'map-settings',
    loadChildren: () => import('./pages/map-settings/map-settings.module').then(m => m.MapSettingsPageModule)
  },
  {
    path: 'mqtt-lost-detail',
    loadChildren: () => import('./pages/mqtt-lost-detail/mqtt-lost-detail.module').then(m => m.MqttLostDetailPageModule)
  },
  {
    path: 'multiple-order-transition',
    loadChildren: () => import('./pages/multiple-order-transition/multiple-order-transition.module').then(m => m.MultipleOrderTransitionPageModule),
  },
  {
    path: 'notification-center',
    loadChildren: () => import('./pages/notification-center/notification-center.module').then(m => m.NotificationCenterPageModule),
  },
  {
    path: 'optimize-options',
    loadChildren: () => import('./pages/optimize-options/optimize-options.module').then(m => m.OptimizeOptionsPageModule),
  },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryPageModule)
  },
  {
    path: 'order-list-options-menu',
    loadChildren: () => import('./pages/order-list-options-menu/order-list-options-menu.module').then(m => m.OrderListOptionsMenuPageModule),
  },
  {
    path: 'reports-forms',
    loadChildren: () => import('./pages/reports-forms/reports-forms.module').then(m => m.ReportsFormsPageModule)
  },
  {
    path: 'select-recipient',
    loadChildren: () => import('./pages/select-recipient/select-recipient.module').then(m => m.SelectRecipientPageModule),
  },
  {
    path: 'signature',
    loadChildren: () => import('./pages/signature/signature.module').then(m => m.SignaturePageModule)
  },
  {
    path: 'sort-popover',
    loadChildren: () => import('./pages/sort-popover/sort-popover.module').then(m => m.SortPopoverPageModule)
  },
  {
    path: 'map-filter',
    loadChildren: () => import('./pages/map-filter/map-filter.module').then(m => m.MapFilterPageModule)
  },
  {
    path: 'transition-detail',
    loadChildren: () => import('./pages/transition-detail/transition-detail.module').then(m => m.TransitionDetailPageModule),
  },
  {
    path: 'trips-report',
    loadChildren: () => import('./pages/trips-report/trips-report.module').then(m => m.TripsReportPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule)
  },
  {
    path: 'main-setting',
    loadChildren: () => import('./pages/setting/main-setting/main-setting.module').then(m => m.MainSettingPageModule)
  },
  {
    path: 'display-setting',
    loadChildren: () => import('./pages/setting/display-setting/display-setting.module').then(m => m.DisplaySettingPageModule)
  },
  {
    path: 'location-setting',
    loadChildren: () => import('./pages/setting/location-setting/location-setting.module').then(m => m.LocationSettingPageModule)
  },
  {
    path: 'signature-pin-modal',
    loadChildren: () => import('./pages/signature-pin-modal/signature-pin-modal.module').then(m => m.SignaturePinModalPageModule)
  },
  {
    path: 'password-change-modal',
    loadChildren: () => import('./pages/password-change-modal/password-change-modal.module').then( m => m.PasswordChangeModalPageModule)
  },
  {
    path: 'profile-view',
    loadChildren: () => import('./pages/profile/profile-view/profile-view.module').then( m => m.ProfileViewPageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
