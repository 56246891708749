<ion-header>

  <ion-toolbar>
    <ion-title>{{ 'pages.map.settings.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">
  <ion-item>
    <ion-label>{{ 'pages.map.settings.rank.label' | translate }}</ion-label>
    <ion-input [legacy]="true" type="number" [(ngModel)]="rank"></ion-input>
  </ion-item>
</ion-content>
