import {Injectable} from '@angular/core';
import {MqttConfiguration} from '@models/configuration/mqtt-configuration.model';
import {MqttServer} from '@models/configuration/mqtt-server.model';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {DeviceInfo} from '@models/information/device-info.model';
import {ConnectionLostDetailPage} from '@app/pages/connection-lost-detail/connection-lost-detail.page';
import {MqttLostDetailPage} from '@app/pages/mqtt-lost-detail/mqtt-lost-detail.page';
import {classToClass} from '@utils/json-converter/json-converter';
import {ModalController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import Timer = NodeJS.Timer;

@Injectable({
  providedIn: 'root'
})
export class ConnectionStatusService {

  connectionStatusTimeout: Timer;
  mqttStatusTimeout: Timer;
  mqttServerCheckInterval: Timer;

  private lastStatus: ConnectionStatus = new ConnectionStatus();
  private mqttConfiguration: MqttConfiguration = null;

  private _connectionStatusTrigger: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(this.lastStatus);
  public connectionStatusSubscription: Observable<ConnectionStatus> = this._connectionStatusTrigger.asObservable();

  constructor(private mobileContextService: MobileContextService,
              private deviceInfo: DeviceInfo,
              private alertCtrl: AlertController,
              private translateService: TranslateService,
              private log: LogService,
              private modalCtrl: ModalController,
  ) {

    this.deviceInfo.networkStatus.networkChange
      .subscribe(next => {
        this.lastStatus.isNetworkConnected = next;
        if (next) {
          this.killConnectionStatusTimeout();
          this.lastStatus.isNetworkConnectedForUser = true;
        } else {
          this.notifyConnectionStatusListenersAfterTimeout();
        }
        this.log.trace('New resolved network status is : ', this.lastStatus);
        this.publishStatus(this.lastStatus);
      });

    this.deviceInfo.networkStatus.mqttChange
      .subscribe((next: { connected: false, reconnecting: false }) => {
        this.lastStatus.isMqttReconnecting = next.reconnecting;
        this.lastStatus.isMqttConnected = next.connected;
        if (next.connected) {
          this.killMqttStatusTimeout();
          this.killConnectionStatusTimeout();
          this.lastStatus.isMqttConnectedForUser = true;
          this.lastStatus.isNetworkConnectedForUser = true;
        } else {
          this.notifyMqttStatusListenersAfterTimeout();
        }
        this.log.trace('New resolved network status is : ', this.lastStatus);
        this.publishStatus(this.lastStatus);
      });

    this.mobileContextService.providedConfigurationObservable
      .subscribe(configuration => {
        if (configuration && configuration.mqtt) {
          this.mqttConfiguration = configuration.mqtt;
        } else {
          this.mqttConfiguration = null;
        }
      });

    this.publishStatus(this.lastStatus);

  }

  getConnectionErrorMessage() {
    if (!this.lastStatus.isMqttConnectedForUser && !this.lastStatus.isNetworkConnectedForUser) {
      return 'messages.errors.connection_problem_menu_label';
    } else if (!this.lastStatus.isMqttConnectedForUser && this.lastStatus.isNetworkConnectedForUser) {
      return 'messages.errors.mqtt_problem_menu_label';
    } else {
      return 'messages.errors.connection_problem_menu_label';
    }
  }

  displayTroobleshootingAlert() {
    let alert;
    const messages = this.getTroubleshootingMessages(this.lastStatus);
    if (messages) {
      alert = this.alertCtrl.create({
        header: messages.title,
        subHeader: messages.subTitle,
        cssClass: 'alert-breaklines',
        buttons: [this.translateService.instant('actions.ok')]
      }).then(res => {
        res.present();
      });
    }
  }

  async displayTroobleshootingModal() {
    if (this.lastStatus.isNetworkConnectedForUser && this.lastStatus.isMqttConnectedForUser) {
      this.displayTroobleshootingAlert();
    } else {
      const page = !this.lastStatus.isNetworkConnectedForUser ? ConnectionLostDetailPage : MqttLostDetailPage;
      this.modalCtrl.create({
        component: page,
        componentProps: {connectionDetailMessage: this.getTroubleshootingMessages(this.lastStatus)},
        cssClass: 'small-modal'
      })
        .then(res => {
          res.present();
        });
    }
  }

  getTroubleshootingMessages(connectionStatus: ConnectionStatus): any {
    let messages;
    if (connectionStatus.isMqttConnectedForUser && connectionStatus.isNetworkConnectedForUser) {
      messages = {
        title: this.translateService.instant('messages.info.connection_status.title'),
        subTitle: this.translateService.instant('messages.info.connection_status.subTitle')
      };
    } else if (!connectionStatus.isMqttConnectedForUser && connectionStatus.isNetworkConnectedForUser) {
      messages = {
        title: this.translateService.instant('messages.errors.connection_status.no_mqtt.title'),
        subTitle: this.translateService.instant('messages.errors.connection_status.no_mqtt.subTitle')
      };
    } else if (!connectionStatus.isNetworkConnectedForUser) {
      messages = {
        title: this.translateService.instant('messages.errors.connection_status.no_internet.title'),
        subTitle: this.translateService.instant('messages.errors.connection_status.no_internet.subTitle')
      };
    }
    return messages;
  }

  private notifyConnectionStatusListenersAfterTimeout() {
    this.connectionStatusTimeout = this.connectionStatusTimeout || setTimeout(() => {
      this.lastStatus.isNetworkConnectedForUser = false;
      this.publishStatus(this.lastStatus);
    }, 30 * 1000);
  }

  private notifyMqttStatusListenersAfterTimeout() {
    this.mqttServerCheckInterval = this.mqttServerCheckInterval || setInterval(() => {
      if (this.mqttConfiguration) {
        this.mqttConfiguration.servers.forEach((mqttServer: MqttServer) => {
          this.wsCheck(mqttServer.host, mqttServer.port);
        });
      }
    }, 20 * 1000);

    this.mqttStatusTimeout = this.mqttStatusTimeout || setTimeout(() => {
      this.lastStatus.isMqttConnectedForUser = false;
      this.publishStatus(this.lastStatus);
    }, 30 * 1000);
  }

  private wsCheck(target, port) {
    try {
      const ws = new WebSocket(`wss://${target}:${port}`); // FIXME protocol should come in args
      setTimeout(() => {
        if (ws.readyState === 0) {
          this.wsStatusLog(target, port, 'closed');
        } else {
          this.wsStatusLog(target, port, 'open');
        }
        ws.close();
      }, 7 * 1000); // Wait this long before checking readyState
    } catch (e) {
      // nothing to do here
    }
  }

  private wsStatusLog(target, port, status) {
    this.log.info(`MqttServer ${target} on port ${port} is ${status}`);
  }

  private killConnectionStatusTimeout() {
    clearTimeout(this.connectionStatusTimeout);
    this.connectionStatusTimeout = null;
  }

  private killMqttStatusTimeout() {
    clearTimeout(this.mqttStatusTimeout);
    this.mqttStatusTimeout = null;
    clearInterval(this.mqttServerCheckInterval);
    this.mqttServerCheckInterval = null;
  }

  private publishStatus(status: ConnectionStatus) {
    let object = null;
    if (status) {
      object = classToClass(ConnectionStatus, status);
    }
    this._connectionStatusTrigger.next(object);
  }

  public getLastKnownConnectionStatus(): ConnectionStatus {
    return this._connectionStatusTrigger.value;
  }

}
