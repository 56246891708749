<ion-list>
  <ion-radio-group [value]="statusSettings.sortOrder" (ionChange)="sortChanged($event)">
    <ion-list-header>
      <span translate="options.sort.label"></span>
    </ion-list-header>

    <ion-item>
        <fa-icon [icon]="['far','sort-alpha-down']" size="lg" slot="start"></fa-icon>
      <ion-label class="ion-text-wrap">
        <span translate="options.sort.lastName"></span>
      </ion-label>
      <ion-radio value="lastName" slot="end"></ion-radio>
    </ion-item>

    <ion-item>
        <fa-icon [icon]="['far','sort-numeric-down']" size="lg" slot="start"></fa-icon>
      <ion-label class="ion-text-wrap">
        <span translate="options.sort.rank"></span>
      </ion-label>
      <ion-radio value="sequence" slot="end"></ion-radio>
    </ion-item>

    <ion-item>
        <fa-layers class="fa-fw fa-1x" slot="start">
          <fa-icon [icon]="['far','arrow-down']" transform="grow-5 left-5"></fa-icon>
          <fa-layers-text transform="shrink-6 right-5 up-4" style="font-weight: bold;"
                          content="{{'km' | DistanceUnit:settings.distanceUnit}}"></fa-layers-text>
        </fa-layers> &nbsp;
      <ion-label class="ion-text-wrap">
        <span translate="options.sort.distance"></span>
      </ion-label>
      <ion-radio value="distance" slot="end"></ion-radio>
    </ion-item>

    <ion-item *ngIf="settings.showAverageDeliveryMoment">
        <fa-layers class="fa-fw fa-1x" slot="start">
          <fa-icon [icon]="['far','arrow-down']" transform="grow-5 left-5"></fa-icon>
          <fa-icon [icon]="['far', 'clock']" transform="shrink-3 right-5 up-3"></fa-icon>
        </fa-layers>&nbsp;
      <ion-label class="ion-text-wrap">
        <span translate="options.sort.avgtime"></span>
      </ion-label>
      <ion-radio value="avgtime" slot="end"></ion-radio>
    </ion-item>
  </ion-radio-group>

</ion-list>


