import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { BuildInfo } from '@models/information/build-info.model';
import { DeviceInfo } from '@models/information/device-info.model';
import {
  HardwarePermission,
  LocalNotificationPermission,
  LocationPermission,
  PushPermission,
  PushType
} from '@models/information/permissions.model';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { EndpointService } from '@services/endpoint/endpoint.service';
import { LogService } from '@services/log/log.service';
import { classToPlain, plainToClass } from '@utils/json-converter/json-converter';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Subscription } from 'rxjs';
import { filter, map, skip, take, timeout } from 'rxjs/operators';
import Timer = NodeJS.Timer;
import { DeviceUtils } from '@services/utils/device-utils';
import { AppSettings, APP_SETTINGS } from '@app/app.settings';
import { AppEvents } from '@services/app-events/app-events';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';
import { CameraPluginPermissions } from '@capacitor/camera';

const CONFIG = {
  PERMISSIONS_PATH: '/mobile/v2/permissions'
};

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  private isCordova: boolean;

  batteryChangeSubscription: Subscription;

  checkServerConnectionInterval: Timer;

  private readonly CONNECTIVITY_CHECK_INTERVAL: number; // ping interval in seconds
  private readonly CONNECTIVITY_CHECK_PATH: string;
  private CONNECTIVITY_CHECK_URL: string; // ping url

  constructor(private httpClient: HttpClient,
    private platform: Platform,
    private diagnostic: Diagnostic,
    private deviceInfo: DeviceInfo,
    private isDebug: IsDebug,
    private zone: NgZone,
    private endpointService: EndpointService,
    private log: LogService,
    private deploy: Deploy,
    private alertController: AlertController,
    private translateService: TranslateService,
    private deviceUtils: DeviceUtils,
    @Inject(APP_SETTINGS) appSettings: AppSettings,
    private appEvents: AppEvents,
    private capacitorPlugins: CapacitorPlugins
  ) {
    this.log.debug('Constructing DeviceInfoService...', this.deviceInfo);

    this.httpClient.get('assets/build.json')
      .pipe(
        map((body: object) => plainToClass(BuildInfo, body))
      )
      .subscribe((buildInfo: BuildInfo) => {
        if (buildInfo && buildInfo.version) {
          this.deviceInfo.buildInfo = buildInfo;
          this.platform.ready()
            .then(() => {
              this.deploy.configure({
                appId: 'af33d998'
              });
            });

          this.log.info('#######################################');
          this.log.info('App version is : ' + buildInfo.version, buildInfo);
          this.log.info('#######################################');
        }
      });

    // NOTE: sanity check for ping interval
    this.CONNECTIVITY_CHECK_INTERVAL = (appSettings.CONNECTIVITY_CHECK_INTERVAL != null && !isNaN(appSettings.CONNECTIVITY_CHECK_INTERVAL) && appSettings.CONNECTIVITY_CHECK_INTERVAL >= 5 ? appSettings.CONNECTIVITY_CHECK_INTERVAL : 5);
    this.CONNECTIVITY_CHECK_PATH = appSettings.CONNECTIVITY_CHECK_PATH || '/heartbeat/ping';
  }

  public async init(): Promise<any> {
    const blockingPromises = [];

    this.log.info('Initializing DeviceInfoService...');
    // Testing without ionic's network objects
    const isRunningOnRealDeviceOrSimulator = await this.deviceUtils.isRunningOnRealDeviceOrSimulator();
    // NOTE: on a web platform we perioically check if the server is alive
    // this is the only reliable way to monitor the connectivity status
    if (isRunningOnRealDeviceOrSimulator === false) {
      this.log.info('platform is not cordova !');
      this.deviceInfo.networkStatus.isConnected = true;

      // NOTE: we should set CONNECTIVITY_CHECK_URL after the endpoint is set/updated (note that we skip the
      // first value emitted which is the default production endpoint)
      this.endpointService.observableEndpoint
        .pipe(
          skip(1),
          filter(endpoint => endpoint != null)
        )
        .subscribe((currentEndpoint: string) => {
          this.CONNECTIVITY_CHECK_URL = `${currentEndpoint}${this.CONNECTIVITY_CHECK_PATH}`;
          this.checkServerConnectionInterval = this.triggerIntervalServerStatusChange(false);
        });

      blockingPromises.push(
        this.getVisitorId().then(visitorId => {
          this.deviceInfo.uuid = 'browser-' + visitorId;
          this.log.trace('Browser UUID resolved : ', this.deviceInfo.uuid);
        }));

      this.deviceInfo.isDebug = true;
    }
    this.isCordova = this.platform.is('cordova');
    if (this.appEvents.isUserAuthorized() === true) {
      this.updatePermissions(false);
    } else {
      this.appEvents.authorizationSuccessAnnounced$
        .pipe(
          filter((val) => val != null),
          take(1)
        )
        .subscribe(() => {
          this.updatePermissions(false);
        });
    }

    // ================= platform preferred language =================
    const res = navigator.language;
    this.deviceInfo.preferredLanguage = res;
    // ================= Battery info =================
    setInterval(() => {
      this.capacitorPlugins.getDevicePlugin().getBatteryInfo()
        .then(deviceInfo => {
          if (deviceInfo) {
            this.deviceInfo.batteryLevel = deviceInfo.batteryLevel;
            this.deviceInfo.isPlugged = deviceInfo.isCharging;
          }
        }, error => {
          console.error('Unable to get app info.', error);
        });
    }, 5 * 60 * 1000);

    this.isDebug.getIsDebug()
      .then((isDebug: boolean) => {
        this.deviceInfo.isDebug = isDebug;
        Sentry.onLoad(() => {
          Sentry.init({
            environment: isDebug ? 'development' : 'production',
            release: this.deviceInfo.unifiedVersion
          });
        });
      }
      )
      .catch((error: any) => this.log.error(error));

    // NOTE: on a native platform we rely connectivity status check to the capacitor network plugin
    // Please note that the web implementation of this plugin is not reliable - see
    // https://github.com/ionic-team/capacitor/blob/260feebb78d67c431de7831548dfd4271ceb5079/core/src/web/network.ts#L28 and
    // https://github.com/ionic-team/capacitor/blob/260feebb78d67c431de7831548dfd4271ceb5079/core/src/web/network.ts#L48
    if (isRunningOnRealDeviceOrSimulator === true) {
      setTimeout(() => { // See : https://github.com/ionic-team/ionic-native/issues/2317
        // ================= Network =================
        this.capacitorPlugins.getNetworkPlugin().getStatus().then(status => {
          this.deviceInfo.networkStatus.setType(status.connectionType);
          if (!status.connected) {
            this.checkServerConnectionInterval = this.triggerIntervalServerStatusChange(true);
          } else {
            clearInterval(this.checkServerConnectionInterval);
          }
          this.deviceInfo.networkStatus.isConnected = status.connected;
        }, error => {
          this.log.error('Unable to get network status!', error);
        });

        this.capacitorPlugins.getNetworkPlugin().addListener('networkStatusChange', (status) => {
          this.log.info('Network status changed', status);
          this.zone.run(() => {
            this.deviceInfo.networkStatus.isConnected = status.connected;
            this.deviceInfo.networkStatus.setType(status.connectionType);
            if (!status.connected) {
              this.checkServerConnectionInterval = this.triggerIntervalServerStatusChange(true);
            } else {
              clearInterval(this.checkServerConnectionInterval);
            }
          });
        });
      }, 500);
    }

    // May be needed for running in a simulator
    // if (this.deviceInfo.uuid == null) {
    //   console.error('UUID is null, generating a new one.');
    //   this.deviceInfo.uuid = uuid4();
    // }

    return Promise.all(blockingPromises);
  }

  triggerIntervalServerStatusChange(clearIntervalWhenAlive: boolean) {
    if (this.checkServerConnectionInterval) {
      clearInterval(this.checkServerConnectionInterval);
    }
    return setInterval(() => {
      this.checkIfServerAlive(clearIntervalWhenAlive);
    }, this.CONNECTIVITY_CHECK_INTERVAL * 1000);
  }

  private checkIfServerAlive(clearIntervalWhenAlive: boolean) {
    this.httpClient.options(this.CONNECTIVITY_CHECK_URL, { responseType: 'text' })
      .pipe(
        timeout((this.CONNECTIVITY_CHECK_INTERVAL - 1) * 1000),
      )
      .subscribe(() => {
        this.log.debug('Ping server: I am alive');
        this.deviceInfo.networkStatus.isConnected = true;
        if (clearIntervalWhenAlive === true) {
          clearInterval(this.checkServerConnectionInterval);
        }
      },
        () => {
          this.log.debug('Ping server: I am dead');
          this.deviceInfo.networkStatus.isConnected = false;
        });
  }

  public updatePermissions(triggerLocationPermissionPopup: boolean = true, triggerLocalNotificationPermissionPopup: boolean = true): Array<Promise<any>> {
    const promises = [];
    promises.push(this.updateCameraPermission(this.deviceInfo.permissions.camera));
    promises.push(this.updatePushPermission(this.deviceInfo.permissions.push));
    promises.push(this.updateNotificationPermission(this.deviceInfo.permissions.localNotification, triggerLocalNotificationPermissionPopup));
    promises.push(this.updateLocationPermission(this.deviceInfo.permissions.location, triggerLocationPermissionPopup));
    Promise.all(promises)
      .then(_results => {
        this.log.debug('Update permissions done.', this.deviceInfo.permissions);
      });
    return promises;
  }

  private updateCameraPermission(cameraPermission: HardwarePermission): Array<Promise<any>> {
    const promises = [];
    try {
      const cameraPermissions: CameraPluginPermissions = {
        permissions: ['camera', 'photos']
      };
      promises.push(this.capacitorPlugins.getCameraPlugin().requestPermissions(cameraPermissions).then(
        (next) => {
          // QUESTION: do we need both camera and photo library permissions? my assumption is that we need both
          cameraPermission.authorizationStatus = `camera: ${next.camera}; photos: ${next.photos}`;
          if (next.camera === 'granted' && next.photos === 'granted') {
            cameraPermission.isAuthorized = true;
            cameraPermission.isAvailable = true;
            cameraPermission.isPresent = true;
          }
        }
      ));
    } catch (e) {
      this.log.error('some error in camera', e);
      promises.push(Promise.reject(e));
    }
    return promises;
  }


  updateNotificationPermission(notificationPermission: LocalNotificationPermission, triggerLocalNotificationPermissionPopup: boolean = true): Array<Promise<any>> {
    const promises = [];

    if (triggerLocalNotificationPermissionPopup) {
      this.log.info('Requesting notification permission');
      this.capacitorPlugins.getLocalNotificationsPlugin().requestPermissions()
        .then(result => {
          if (result.display === 'granted') {
            this.log.info('Notification permission has been granted ?', result);
          }
        }, reason => {
          this.log.info('Cannot request notification permission!', reason);
        });
    }

    try {
      promises.push(this.capacitorPlugins.getLocalNotificationsPlugin().requestPermissions()
        .then(result => {
          if (result) {
            notificationPermission.enabled = result.display === 'granted';
          } else {
            notificationPermission.enabled = false;
          }
        }, reason => {
          this.log.error('Cannot check local notification permission status', reason);
        }
        ));
    } catch (e) {
      this.log.error('some error in local notifications permission check', e);
      promises.push(Promise.reject(e));
    }

    return promises;
  }


  updatePushPermission(pushPermission: PushPermission): Array<Promise<any>> {
    const promises = [];
    // console.log('checking push notification');
    if (this.isCordova) {
      try {
        if (!this.platform.is('ios')) {
          return [Promise.resolve('Push : not on iOS.')];
        }
        promises.push(this.diagnostic.isRemoteNotificationsEnabled()
          .then(enabled => {
            pushPermission.isRemoteNotificationEnabled = enabled;
          }));

        promises.push(this.diagnostic.isRegisteredForRemoteNotifications()
          .then(registered => {
            pushPermission.isRegistered = registered;
          }));

        promises.push(this.diagnostic.getRemoteNotificationTypes()
          .then(type => {
            if (type) {
              const pushType = new PushType();
              pushType.alert = type.alert;
              pushType.sound = type.sound;
              pushType.badge = type.badge;
              pushPermission.type = pushType;
            }
          }, () => {
          }));

        promises.push(this.diagnostic.getRemoteNotificationsAuthorizationStatus()
          .then(status => {
            pushPermission.authorizationStatus = status;
          }));
      } catch (e) {
        this.log.error('some error in push', e);
        promises.push(Promise.reject(e));
      }
    }
    return promises;
  }

  updateLocationPermission(locationPermission: LocationPermission, triggerPermissionPopup: boolean = true): Array<Promise<any>> {
    const promises = [];
    try {
      if (triggerPermissionPopup) {
        promises.push(this.capacitorPlugins.getGeolocationPlugin().getCurrentPosition().then(
          (next) => {
            locationPermission.isEnabled = true;
          }, async err => {
            if (this.platform.is('android')) {
              const alert = await this.alertController.create(
                {
                  header: this.translateService.instant('messages.locationDisabled.alert_title'),
                  message: this.translateService.instant('messages.locationDisabled.device_localisation_not_enable'),
                  buttons: [this.translateService.instant('messages.locationDisabled.ok_button')]
                }
              );
            }
            this.log.error('location not enabled');
            locationPermission.isEnabled = false;
          })
        );
      }
      promises.push(this.capacitorPlugins.getGeolocationPlugin().checkPermissions().then(
        (next) => {
          if (next.coarseLocation === 'granted') {
            locationPermission.isAuthorized = true;
            locationPermission.isAvailable = true;
          } else {
            locationPermission.isAuthorized = false;
            locationPermission.isAvailable = false;
          }
          locationPermission.authorizationStatus = next.coarseLocation;
        }
      ));
    } catch (e) {
      this.log.error('some error in gps', e);
      promises.push(Promise.reject(e));
    }
    /*if (this.isCordova) {
      try {
        promises.push(this.diagnostic.isLocationEnabled()
          .then(enabled => {
            locationPermission.isEnabled = enabled;
          }));

        promises.push(this.diagnostic.isLocationAvailable()
          .then(available => {
            locationPermission.isAvailable = available;
          }));

        promises.push(this.diagnostic.getLocationAuthorizationStatus()
          .then(status => {
            locationPermission.authorizationStatus = status;
          }));

        promises.push(this.diagnostic.isLocationAuthorized()
          .then(authorized => {
            locationPermission.isAuthorized = authorized;
          }));

        if (this.platform.is('android')) {
          promises.push(this.diagnostic.getLocationMode()
            .then(mode => {
              locationPermission.mode = mode;
            }));
        }
      } catch (e) {
        this.log.error('some error in gps', e);
        promises.push(Promise.reject(e));
      }
    } else if (this.platform.is('mobileweb')) {
      promises.push(Plugins.Permissions.query({name: PermissionType.Geolocation})
        .then((next) => {
            if (next.state === 'granted') {
              locationPermission.isAuthorized = true;
              locationPermission.isEnabled = true;
              locationPermission.isAvailable = true;
            }
          }
        ));
    }*/
    return promises;
  }

  // private setSimInfo(simInfo: object): void {
  //   this.deviceInfo.simInfo = simInfo;
  // }

  public submitPermissions() {
    const url = this.endpointService.currentEndpoint + CONFIG.PERMISSIONS_PATH + '?id=' + this.deviceInfo.uuid;

    const permissions = classToPlain(this.deviceInfo.permissions);

    return this.httpClient.post(url, permissions, { responseType: 'text' })
      .subscribe();
  }

  /**
   * Method to return the browser identifier.
   * It will contain an empty string when the visitor can't be identified, for example when it's a search bot.
   * If the identification product is disabled, the value is a dummy value.
   * For more details please see https://dev.fingerprint.com/docs/js-agent#visitorid and the usage sample here
   * https://github.com/fingerprintjs/fingerprintjs/blob/master/playground/index.ts
   *
   * @returns string
   */
  private async getVisitorId(): Promise<string> {
    const fp = await FingerprintJS.load({ debug: true });
    const { visitorId } = await fp.get();
    return visitorId;
  }

}
