<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button (click)="close()">
        <img src="assets/images/logo_menu.png"/>
      </ion-menu-button>
    </ion-buttons>
    <ion-title>
      <span translate="userProfile.profile"></span> - <span translate="userProfile.edit"></span>
    </ion-title>
    <ion-button (click)="close()" slot="end" fill="clear" color="light" style="margin-right:5px;">
      <fa-icon [icon]="['fas','times']" style="color:#424242 !important;"></fa-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" [noValidate]="false" style="padding-top:15px;">
    <ion-item>
      <ion-label position="stacked" translate="userProfile.firstName"></ion-label>
      <ion-input [legacy]="true" formControlName="firstName" required type="text"></ion-input>
    </ion-item>

    <ion-item class="ion-margin-top">
      <ion-label position="stacked" translate="userProfile.lastName"></ion-label>
      <ion-input [legacy]="true" formControlName="lastName" required type="text"></ion-input>
    </ion-item>

    <ion-item class="ion-margin-top">
      <ion-label position="stacked" translate="userProfile.phoneNumber"></ion-label>
      <ion-input [legacy]="true" formControlName="phoneNumber" type="text"></ion-input>
    </ion-item>

    <ion-item class="ion-margin-top ion-margin-bottom">
      <ion-label position="stacked" translate="userProfile.email"></ion-label>
      <ion-input [legacy]="true" formControlName="email" type="email"></ion-input>
    </ion-item>

    <ion-button color="primary" type="submit" class="ion-margin-top" style="margin-left:10px;"><span translate="userProfile.save"></span></ion-button>
  </form>
</ion-content>
