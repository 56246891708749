import {SimpleChanges} from '@angular/core';
import {Input} from '@angular/core';
import {OnDestroy} from '@angular/core';
import {OnChanges} from '@angular/core';
import {Component} from '@angular/core';
import {UserSettings} from '@models/settings/settings.model';
import {MobilusDesiredArrivalTimePipe} from '@app/pipes/distance/mobilus-distance.pipe';
import {TranslateService} from '@ngx-translate/core';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'mobilus-desired-arrival',
  templateUrl: './desired-arrival.component.html',
  styleUrls: ['./desired-arrival.component.scss'],
})
export class DesiredArrivalComponent implements OnChanges, OnDestroy {
  @Input() type: string;
  @Input() start: string;
  @Input() end: string;
  @Input() constraint = true;

  text: string;
  settings: UserSettings = new UserSettings();
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(private translateService: TranslateService,
              private mobileContextService: MobileContextService,
              private mobilusTimePipe: MobilusDesiredArrivalTimePipe, ) {
    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(userSettings => {
        this.settings = userSettings;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (this.type) {
      case 'BEFORE':
        this.text = this.translateService.instant('desiredArrival.before', {start: this.mobilusTimePipe.transform(this.start, this.settings.hourFormat)});
        break;
      case 'BETWEEN':
        this.text = this.translateService.instant('desiredArrival.between', {
          start: this.mobilusTimePipe.transform(this.start, this.settings.hourFormat),
          end: this.mobilusTimePipe.transform(this.end, this.settings.hourFormat)
        });
        break;
      case 'AFTER':
        this.text = this.translateService.instant('desiredArrival.after', {end: this.mobilusTimePipe.transform(this.start, this.settings.hourFormat)});
        break;
      default:
        this.text = '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
