import {MqttServer} from '@models/configuration/mqtt-server.model';
import {MqttTopics} from '@models/configuration/mqtt-topics.model';
import {MQTTStatusEnum} from '@models/push-messages/mqtt-status-enums.model';
import {MqttStatusMessage} from '@models/push-messages/mqtt-status-message.model';
import {classToPlain} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';

@JsonObject('MqttConfiguration')
export class MqttConfiguration {

  @JsonProperty('brokerUsername', String, true)
  username: string = undefined;

  @JsonProperty('brokerPassword', String, true)
  password: string = undefined;

  @JsonProperty('uris', [String], true)
  uris: Array<string> = [];

  @JsonProperty('brokerPath', String, true)
  brokerPath: string = undefined;

  @JsonProperty('protocol', String, true)
  protocol: string = undefined;

  @JsonProperty('servers', [MqttServer], true)
  servers: Array<MqttServer> = [];

  @JsonProperty('topics', MqttTopics, true)
  topics: MqttTopics = undefined;

  asOptions(haulerEmployeeUuid: string, clientId: string): any {
    const options: any = {
      keepalive: 30 * 60, //  maximum time interval between 2 control packets. see: https://www.hivemq.com/blog/mqtt-essentials-part-10-alive-client-take-over
      clientId: clientId + '_' + haulerEmployeeUuid, // PM-396 il est préférable rendre le id unique par device + usager
      protocolId: 'MQTT',
      protocolVersion: 4,
      clean: false,
      reconnectPeriod: 1000,
      connectTimeout: 15 * 1000,
      rejectUnauthorized: false,
      resubscribe: true,
      protocol: this.protocol || 'wss',
      path: this.brokerPath,
      will: {
        topic: this.topics.haulerEmployeeStatuses,
        payload: JSON.stringify(classToPlain(new MqttStatusMessage(MQTTStatusEnum.DISCONNECTED_UNGRACEFULLY, haulerEmployeeUuid, clientId))),
        qos: 2,
        retain: true
      }
    };

    if (this.username && this.password) {
      options.username = this.username;
      options.password = this.password;
    }

    if (this.servers) {
      options.servers = this.servers;
    }

    return options;
  }
}
