<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'location-settings.addLocation' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="dismiss()"
        class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">
  <form #newAddressForm="ngForm" (ngSubmit)="addLocation()">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="12">
          <ion-input [legacy]="true" #addressInput id="address" type="text" [(ngModel)]="address" required name="address"
                     (ionFocus)="writing = true" (ionBlur)="writing = false"
                     placeholder="{{ 'location-settings.address' | translate }}"></ion-input>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="8">
          <ion-input [legacy]="true" type="text" [(ngModel)]="name" required name="name"
                     (ionFocus)="writing = true"
                     placeholder="{{ 'location-settings.name' | translate }}"></ion-input>
        </ion-col>
        <ion-col size="4">
          <ion-button type="submit" [disabled]="newAddressForm.invalid" (click)="addLocation()">
            <span translate="actions.save"></span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer style="height: 300px" [ngStyle]="{ 'visibility': (!writing? 'visible': 'hidden') }">
  <div id="user_location_adder_page_map_canvas" [ngStyle]="{ 'visibility': (connected && !writing? 'visible': 'hidden') }"></div>
  <div id="errorPanel" *ngIf="(!connected || mapLoadFailed)">
    <p *ngIf="!connected">
      {{ 'messages.mapNotAvailableOffline' | translate }}
    </p>
    <p *ngIf="connected && mapLoadFailed">
      {{ 'messages.failedToLoadGoogleMapsSDK' | translate }}
    </p>
  </div>
</ion-footer>