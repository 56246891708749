import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ConnectionStatus} from '@models/information/connection-status.model';
import {UserSettings} from '@models/settings/settings.model';
import {UserProfile} from '@models/user-profile.model';
import {AdminPage} from '@app/pages/admin/admin.page';
import {DiagnosticPage} from '@app/pages/diagnostic/diagnostic.page';
import {HelpPage} from '@app/pages/help/help.page';
import {IsDebug} from '@awesome-cordova-plugins/is-debug/ngx';
import {
  AlertController,
  IonNav,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform,
  ToastController
} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '@services/auth/authentication.service';
import {TokenService} from '@services/auth/token.service';
import {AwaitingTransitionStoreService} from '@services/awaiting-transition/awaiting-transition-store.service';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {DeviceInfoService} from '@services/device-info/device-info.service';
import {EndpointService} from '@services/endpoint/endpoint.service';
import {Features} from '@services/features/features.model';
import {FeaturesService} from '@services/features/features.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {IonicDeployStatus} from '@services/ionic-deploy/ionic-deploy-status';
import {IonicDeploy} from '@services/ionic-deploy/ionic-deploy.service';
import {LanguageService} from '@services/language-service/language.service';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {NotificationService} from '@services/notification-service/notification.service';
import {OrderSyncService} from '@services/order-sync/order-sync.service';
import {RemoteManagementService} from '@services/remote-management/remote-management.service';
import {SettingsManager} from '@services/settings-providers/settings.service';
import {ViewSelection} from '@services/settings-providers/view-selection';
import * as moment from 'moment';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ServerContext} from '@models/check-in/server-context.model';
import {LocationDisclosurePage} from '@app/pages/location-disclosure/location-disclosure.page';
import {OverlayEventDetail} from '@ionic/core';
import {ProfileViewPage} from '@app/pages/profile/profile-view/profile-view.page';
import {Policies} from '@models/check-in/policies.model';
import { AppEvents } from '@services/app-events/app-events';
import { CapacitorPlugins } from '@services/capacitor-plugins/capacitor-plugins';

const CONFIG = {
  MAIN_MENU_ID: 'MAIN_MENU',
  GA: {
    EVENT: {
      DRAWER_FORCING_SYNC: {
        NAME: 'force-sync',
        ACTION: 'on-drawer-menu'
      }
    }
  }
};

@Component({
  selector: 'mobilus-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MyApp implements OnInit, OnDestroy {
  ViewSelection: typeof ViewSelection = ViewSelection;
  @ViewChild(IonNav) nav: IonNav;

  public userProfile: UserProfile;
  private count = 0;
  debugging = false;
  onWeb: boolean;
  private unsubscribe: Subject<void> = new Subject<void>();
  public isCordova = true;
  deployStatus: IonicDeployStatus;
  pendingNotifications: number;
  connectionStatus: ConnectionStatus = new ConnectionStatus();
  private toast: ToastController;
  private lastUpdateReminder: moment.Moment;
  private isAppInForeground = true;
  private settings: UserSettings = new UserSettings(true);
  private features: Features = new Features();
  public isMobileWeb = false;
  public isAndroid = false;
  public localFirstRun = true;
  policies: Policies;

  constructor(private platform: Platform,
              private endpointService: EndpointService,
              private tokenService: TokenService,
              private authenticationService: AuthenticationService,
              private deviceInfoService: DeviceInfoService,
              private remoteManagementService: RemoteManagementService,
              private languageService: LanguageService,
              private log: LogService,
              private mobileContextService: MobileContextService,
              private ga: MblsAnalyticsService,
              private translateService: TranslateService,
              private alertCtrl: AlertController,
              private navCtrl: NavController,
              private loadingCtrl: LoadingController,
              private awaitingTransitionStore: AwaitingTransitionStoreService,
              private optionProvider: SettingsManager,
              private modalCtrl: ModalController,
              private sync: OrderSyncService,
              private ionicDeploy: IonicDeploy,
              private settingsManager: SettingsManager,
              private featuresService: FeaturesService,
              private toastCtrl: ToastController,
              private notificationService: NotificationService,
              private connectionStatusService: ConnectionStatusService,
              private menuCtrl: MenuController,
              private router: Router,
              private isDebug: IsDebug,
              private appEvents: AppEvents,
              private capacitorPlugins: CapacitorPlugins
  ) {
    platform.ready()
      .then(() => {
        this.log.info('Platform.ready, starting');
        this.platformReady();

        this.languageService.initDefaultSettings();

        this.isCordova = this.platform.is('cordova');
        this.platform.pause.subscribe(() => {
          this.isAppInForeground = false;
        });
        this.platform.resume.subscribe(() => {
          this.isAppInForeground = true;
        });

        this.capacitorPlugins.getDevicePlugin().getInfo().then(info => {
          this.onWeb = info.platform === 'web';
        });

        this.isDebug.getIsDebug()
          .then(value => {
            this.debugging = value;
          });
        this.isMobileWeb = this.platform.is('mobileweb');
        this.isAndroid = this.platform.is('android');
      });

    combineLatest([
      this.mobileContextService.userProfileObservable,
      this.mobileContextService.userSettingsObservable
    ])
      .subscribe(([userProfile, userSettings]) => {
        this.userProfile = userProfile;
        if (userProfile && userSettings && userSettings.firstRun) {
          // this.openHelp();
          // Change value and save it.
          this.settingsManager.setFirstRun(false);
        }
        this.settings = userSettings;
        // this.checkIfWePlayIntro();  // Disabling intros. see PM-623

        // For android only: background geolocation tracking consent popup
        if (this.localFirstRun && this.userProfile && this.settings && !this.settings.dummy) {
          this.localFirstRun = false;
          if (!this.settings.hideLocationDisclosure && this.isAndroid) {
            this.modalCtrl
              .create({
                component: LocationDisclosurePage,
                cssClass: 'modal-l',
                componentProps: {
                  policies: this.policies
                }
              })
              .then(m => {
                m.onDidDismiss()
                  .then((overlayEventDetail: OverlayEventDetail) => {
                    this.settingsManager.setGeotrackingConsentPopupHasBeenSeen(true);
                    if (overlayEventDetail && overlayEventDetail.data && overlayEventDetail.data.disable) {
                      this.settingsManager.setHideLocationDisclosure(true);
                    }
                  });
                m.present();
              });
          } else {
          }
        } else if (!this.localFirstRun && !this.userProfile && (!this.settings || this.settings.dummy)) {
          this.localFirstRun = true;
        }

      });

    this.awaitingTransitionStore.ready()
      .then(() => {
        this.awaitingTransitionStore.countObservable
          .subscribe((count) => {
            this.count = count;
          });
      });


    this.featuresService.featuresObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(features => this.features = features);

    this.mobileContextService.policiesObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(policies => {
        this.policies = policies;
      });
  }

  autoLoginSuccess(userProfile: UserProfile) {
    this.appEvents.notifyAuthorizationSuccess(userProfile);
    this.authenticationService.scheduleRefreshToken();
    this.afterReady();
    this.router.navigateByUrl('/status-tabs');
  }

  loginRequired() {
    // Removed by design. Not necessary with the guard
    // this.router.navigateByUrl('/login');
    this.afterReady();
  }

  platformReady() {
    // Call any initial plugins when ready
    this.platform.ready()
      .then(() => {
        this.deviceInfoService.init()
          .then(() => {
            this.endpointService.init()
              .then(() => {
                this.tokenService.init()
                  .then(() => {
                    this.capacitorPlugins.getNetworkPlugin().getStatus()
                      .then(status => {
                        if (status.connected) {
                          this.onlineLogin();
                        } else {
                          this.offlineLogin();
                        }
                      });
                    this.mobileContextService.getServerStartupContext().subscribe((context: ServerContext) => {
                      if (context) {
                        this.mobileContextService.updatePolicies(context.policies);
                        context.commands.forEach(command => {
                          this.remoteManagementService.executeCommand(command);
                        });
                      }
                    });
                  });
              })
              .catch(error => this.log.fatal('TokenService.init failed ', error));
          });
      });

  }


  private onlineLogin() {
    this.log.info('Starting the app in online mode.');
    this.authenticationService.onlineLogin()
      .subscribe(
        mobileConfiguration => {
          this.log.info('Auto-login successful (ONLINE).');
          this.autoLoginSuccess(mobileConfiguration.userProfile);
        },
        error => {
          this.log.info('Auto-login failed (ONLINE) : ', error);
          if (error && error instanceof HttpErrorResponse && error.status === 0) {
            // Unable to contact the backend, trying an offline login
            this.offlineLogin();
          } else {
            this.loginRequired();
          }
        });
  }

  private offlineLogin() {
    this.log.info('Starting the app in offline mode.');
    this.authenticationService.offlineLogin()
      .subscribe(
        mobileConfiguration => {
          this.log.info('Auto-login successful (OFFLINE).');
          this.autoLoginSuccess(mobileConfiguration.userProfile);
        },
        error => {
          this.log.error('Auto-login failed (OFFLINE) : ', error);
          this.loginRequired();
        }
      );
  }

  public afterReady() {
    // Giving time to render the page
    setTimeout(() => {
      this.capacitorPlugins.getSplashScreenPlugin().hide();
    }, 500);

  }

  logout() {
    if (this.count > 0) {
      this.logoutAlert(this._logout.bind(this));
    } else {
      this._logout();
    }
  }

  private _logout() {
    this.authenticationService.doLogout();
    this.navCtrl.navigateRoot('/login');
    this.close();
  }

  private async logoutAlert(onReady: Function) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('logout.pendingTransitions.title'),
      subHeader: this.translateService.instant('logout.pendingTransitions.description'),
      buttons: [
        {
          text: this.translateService.instant('actions.cancel'),
          role: 'cancel',
          handler: () => {
          }
        }, {
          text: this.translateService.instant('logout.now'),
          handler: () => {
            onReady();
          }
        }, {
          text: this.translateService.instant('logout.wait'),
          handler: async () => {
            if (this.count === 0) {
              this._logout();
            } else {
              const loading = await this.loadingCtrl.create(
                {
                  spinner: 'crescent'
                  // dismissOnPageChange: true
                });
              await loading.present();

              this.awaitingTransitionStore.countObservable
                .subscribe((count) => {
                  if (count === 0) {
                    onReady();
                  }
                });
            }
          }
        }
      ]
    });
    await alert.present();
  }

  openPage(page: any, params?: any) {
    this.navCtrl.navigateRoot(page, params);
    this.close();
  }

  selectView(viewSelection: ViewSelection) {
    this.openPage('/status-tabs', {status: this.optionProvider.getCurrentStatus()});
  }

  async openDiagnostic() {
    const modal = await this.modalCtrl.create({
      component: DiagnosticPage,
      componentProps: {withCloseButton: true}
    });
    await modal.present();
    await this.close();
  }

  async openHelp() {
    const modal = await this.modalCtrl.create({
      component: HelpPage,
      componentProps: {withCloseButton: true},
    });
    await modal.present();
    await this.close();
  }

  async openProfile() {
    const modal = await this.modalCtrl.create({
      component: ProfileViewPage,
      componentProps: {userProfile: this.userProfile}
    });
    await modal.present();
    await this.close();
  }

  async openAdvanced() {
    const modal = await this.modalCtrl.create({
      component: AdminPage
    });
    await modal.present();
    await this.close();
  }


  forceSync() {
    if (this.connectionStatus.isNetworkConnectedForUser) {
      this.ga.trackEvent(CONFIG.GA.EVENT.DRAWER_FORCING_SYNC.NAME, CONFIG.GA.EVENT.DRAWER_FORCING_SYNC.ACTION)
        .catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.DRAWER_FORCING_SYNC} with GA`, error));
      this.sync.fullSync()
        .subscribe(next => {
          this.log.info('Forced full sync completed');
        });
      this.close();

      // FIXME also force sync pending transitions
    }
  }

  installNewVersion() {
    if (this.isMobileWeb) {
      window.location.reload();
    } else {
      this.ionicDeploy.installNewVersion();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.connectionStatus = next;
      });

    this.notificationService.unreadNotificationCountObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((pending: number) => this.pendingNotifications = pending);

    this.ionicDeploy.observableStatus
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(async (status: IonicDeployStatus) => {
        this.deployStatus = status;
        if (this.deployStatus.newVersionAvailable &&
          (!this.lastUpdateReminder ||
            this.lastUpdateReminder.isBefore(moment().subtract(30, 'minutes')))) {
          this.lastUpdateReminder = moment();
          if (this.toast) {
            this.toast.dismiss().catch();
          }
          if (this.isAppInForeground) {
            const toast = await this.toastCtrl.create({
              message: this.translateService.instant('deploy.newVersionAvailable'),
              position: 'bottom',
              cssClass: 'processing-toast',
              buttons: [
                {
                  text: this.translateService.instant('actions.ok'),
                  role: 'cancel',
                }
              ]
            });
            toast.present().catch(error => this.log.error('Unable to present toast:', error));
          }
        }
      });
  }

  getConnectionErrorMessage() {
    return this.connectionStatusService.getConnectionErrorMessage();
  }

  helpConnection() {
    return this.connectionStatusService.displayTroobleshootingModal();
  }

  close() {
    this.menuCtrl.close(CONFIG.MAIN_MENU_ID);
  }

}

