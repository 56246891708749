<!--suppress ALL -->
<ion-header>
  <ion-toolbar color="danger">
    <ion-title><span translate="pages.advanced"></span></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" class="text-xxlarge">
        <fa-icon [icon]="['fal', 'times']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item-group>
      <ion-item-divider>Endpoint</ion-item-divider>
      <ion-item (click)="selectEndpoint()">
        {{ endpointService.currentEndpoint }}
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>Ionic Channel</ion-item-divider>
      <ion-item (click)="selectChannel()">
        {{ ionicInfo.currentChannel }}
      </ion-item>

      <ion-item>
        <ion-button expand="block" (click)="checkInfo()" class="admin-page-buttons-style">
          Check for update availability
        </ion-button>
      </ion-item>

      <ion-item-group>
        <ion-item-divider>Position dans chrome</ion-item-divider>
        <ion-item>
          Current position: <ng-container *ngIf="currentPosition"> {{ currentPosition.latitude }}, {{ currentPosition.longitude }} </ng-container>
        </ion-item>
        <ion-item>
          <ion-button expand="block" (click)="changePosition()" class="admin-page-buttons-style">
            Change position
          </ion-button>
        </ion-item>
      </ion-item-group>

      <ion-item *ngIf="ionicInfo.channel">
        {{ ionicInfo.channel }}
      </ion-item>
      <ion-item *ngIf="ionicInfo.binaryVersion">
        {{ ionicInfo.binaryVersion }}
      </ion-item>
      <ion-item *ngIf="ionicInfo.deployUuid">
        {{ ionicInfo.deployUuid }}
      </ion-item>
    </ion-item-group>

    <ion-item-group>
      <ion-item-divider>Configuration</ion-item-divider>
      <ion-item (click)="selectLogLevel()">Select Level ({{logLevel}})</ion-item>

      <ion-item>
        <ion-label>LogRocket enabled</ion-label>
        <ion-toggle [legacy]="true" [(ngModel)]="logRocketEnabled" (ionChange)="changedLogRocket()"></ion-toggle>
      </ion-item>
    </ion-item-group>

  </ion-list>
</ion-content>
