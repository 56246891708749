import {Component, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {Order} from '@models/business/order.model';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {OrderStatusConverter} from '@models/order-helper/order-status.enum';
import {OrderView} from '@models/order-helper/order-view.model';
import {UserSettings} from '@models/settings/settings.model';
import {Transition} from '@models/workflow/transition.model';
import {ToastController} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {NavParams} from '@ionic/angular';
import {IonTabs} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ConnectionStatusService} from '@services/connection-status-service/connection-status.service';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {TransitionService} from '@services/transition/transition.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import Timer = NodeJS.Timer;

// PROVIDERS
import { DialogsService } from '@services/dialogs/dialogs';

const CONFIG = {
  GA: {
    PAGE_NAME: 'OrderDetailTabsPage',
    EVENT: {
      ORDER_TRANSITION: {
        NAME: 'transition_item',
        ACTION: 'button_pressed'
      }
    }
  }
};

@Component({
  templateUrl: './order-detail-tabs.page.html',
  styleUrls: ['./order-detail-tabs.page.scss'],
})
export class OrderDetailTabsPage {

  private unsubscribe: Subject<void> = new Subject<void>();
  private transitionIsNotRunning = true;
  private timer: Timer;
  private sendSequence = false;
  private settings: UserSettings = new UserSettings(true);
  reference: moment.Moment = moment();
  order: Order;
  view: OrderView;
  isforHistory: boolean;
  manifestEnabled = false;
  orderUrl: string;
  @ViewChild('tabs') tabs: IonTabs;
  tab = 'detail';
  transitions: Array<Transition>;
  connected = true;
  // private modalId: string;
  modal: HTMLIonModalElement;

  constructor(private navParams: NavParams,
              private modalCtrl: ModalController,
              private toastCtrl: ToastController,
              private orderStoreService: OrderStoreService,
              private transitionService: TransitionService,
              private connectionStatusService: ConnectionStatusService,
              private translateService: TranslateService,
              private ga: MblsAnalyticsService,
              private log: LogService,
              private mobileContextService: MobileContextService,
              private router: Router,
              private dialogsService: DialogsService
  ) {
    // NOTE: due to the ionic bug the below does not work since ionic v6.0.2
    // ALEX: I applied the fix as suggested by one of the ionic devs - see https://github.com/ionic-team/ionic-framework/issues/24673#issuecomment-1043428024
    // this.modalCtrl.getTop().then(value => {
    //   this.modalId = value.id;
    // });
    this.view = this.navParams.get('orderView');
    this.isforHistory = this.navParams.get('isforHistory') || false;
    this.order = this.view.order;
    this.transitions = this.sortTransitions(this.view.transitions);
    this.orderUrl = this.router.url;
  }

  ionViewWillEnter() {
    this.timer = setInterval(() => {
      this.reference = moment();
    }, 5000);

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.settings = next;
        const orderStatus = OrderStatusConverter.convert(this.order.status);
        this.sendSequence = this.settings.sequenceOnSelect
          && (OrderStatus.READY_FOR_HAULAGE === orderStatus || OrderStatus.ON_THE_WAY_TO_PROVIDER === orderStatus);
      });

    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.connected = next.isNetworkConnectedForUser;
      });

    this.orderStoreService.getLiveChanges()
      .pipe(
        takeUntil(this.unsubscribe),
        filter(Boolean),
        filter((orderChange: any) => orderChange.doc.id.toString() === this.order.id.toString()),
        filter(() => this.transitionIsNotRunning)
      )
      .subscribe((orderChange: any) => {
        if (orderChange.deleted || (this.order.status !== orderChange.doc.status)) {
          let message = '';
          if (orderChange.deleted) {
            message = this.translateService.instant('messages.order.order_not_available');
          } else if (this.order.status !== orderChange.doc.status) {
            message = this.translateService.instant('messages.order.order_has_changed') + ': (' + this.translateService.instant('workflows.status.' + orderChange.doc.status.toLowerCase()) + ')';
          }
          this.toastCtrl.create({
            message,
            position: 'middle',
            buttons: [{
              text: this.translateService.instant('actions.close'),
              role: 'cancel'
            }],
            duration: 3 * 1000,
            cssClass: 'processing-toast'
          }).then(r => r.present());
          this.transitions = [];
        } else {
          this.order = orderChange.doc;
          this.transitions = this.transitionService.getAvailableTransitionsForOrder(this.order);
        }
      });
    this.mobileContextService.haulerObservable
      .subscribe(hauler => {
        if (hauler && this.order && this.order.tenantId) {
          const provider = hauler.linkedProviders
            .find(p => {
              return p.id.toString() === this.order.tenantId.toString();
            });
          // .find(provider => provider.id.toString() === this.order.tenantId.toString());
          if (provider) {
            this.manifestEnabled = provider.configuration.deliveryConstraints && provider.configuration.deliveryConstraints.manifestEnabled;
          }
        }
      });

    this.unsubscribe.subscribe(() => {
      this.cleanup();
    });
  }

  private cleanup() {
    clearInterval(this.timer);
  }

  public async transit(transition: Transition, callback: Subject<boolean>): Promise<void> {
    if (this.settings.confirmReturnOrderBackToStore === true && this.order.tenantWithAssignation === true && transition.to.name === 'READY_FOR_HAULAGE') {
      const confirmPutOrderBackToStoreMessage = this.translateService.instant('pages.order-detail-tabs.confirm-put-order-back-to-store');
      const confirmButtonText = this.translateService.instant('actions.seen');
      const confirmed: boolean = await this.dialogsService.showConfirmDialog(confirmPutOrderBackToStoreMessage, null, null, 'confirm-warning-dialog', null, confirmButtonText);
      if (confirmed !== true) {
        callback.next(true);
        return;
      }
    }

    this.ga.trackEvent(CONFIG.GA.EVENT.ORDER_TRANSITION.NAME, CONFIG.GA.EVENT.ORDER_TRANSITION.ACTION, 'OrderTransition').catch(error => this.log.error(`Unable to track event ${CONFIG.GA.EVENT.ORDER_TRANSITION} with GA`, error));
    this.transitionIsNotRunning = false;
    this.order.local = true;
    const orderStatus = OrderStatusConverter.convert(this.order.status);
    this.sendSequence = this.settings.sequenceOnSelect
      && (OrderStatus.READY_FOR_HAULAGE === orderStatus || OrderStatus.ON_THE_WAY_TO_PROVIDER === orderStatus);
    if (this.sendSequence && transition.to.name === 'ON_THE_WAY_TO_CUSTOMER') {
      this.order.nextSequenceNumber = ++this.settings.nextSequenceNumber;
    }

    this.transitionService.transitionOrder(this.order, transition)
      .subscribe({
        next: () => {
          this.transitionIsNotRunning = true;
        },
        error: (error) => {
          this.transitionIsNotRunning = true;
        },
        complete: () => {
          this.transitionIsNotRunning = true;
          callback.next(true);
          this.dismiss(transition);
        }
      });
  }

  private sortTransitions(transitions: Array<Transition>) {
    const tran = transitions.sort((a, b): number => {
      if (a.event.rank < b.event.rank) {
        return -1;
      }
      if (a.event.rank > b.event.rank) {
        return 1;
      }
      return 0;
    });
    return tran;
  }

  ionViewWillLeave() {
    this.unsubscribe.next();
  }

  ionViewWillUnload() {
    this.unsubscribe.complete();
  }

  dismiss(transition?: Transition) {
    // this.modalCtrl.dismiss({order: this.order, transition: transition}, null, this.modalId);
    this.modal.dismiss({order: this.order, transition: transition});
  }

  tabChange(tab: string) {
    this.tab = tab;
  }
}
