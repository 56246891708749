<ion-header>

  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button>
        <img src="assets/images/logo_menu.png" />
      </ion-menu-button>
    </ion-buttons>

    <ion-title>{{ 'pages.drawer-menu.hauler_employees' | translate }}</ion-title>
    <ion-buttons slot="end">

      <ion-button *ngIf="settings.buttons.multipleSelection" (click)="toggleSelect()"
        [color]="settings.actions.canSelect ? 'success' : ''" class="top-bar-action-button selection-intro">
        <fa-icon [icon]="['fas', 'check-square']" size="lg" class="fa-bar-button"></fa-icon>
      </ion-button>

      <ion-button slot="icon-only" (click)="fetchOrders()">
        <fa-icon [icon]="['fas', 'sync']" size="lg" slot="end" *ngIf="!loading"></fa-icon>
        <fa-icon [icon]="['fas', 'sync']" size="lg" slot="end" [pulse]="true" *ngIf="loading"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>


<ion-content>
  <mobilus-awaiting-transition-indicator></mobilus-awaiting-transition-indicator>
  <ion-list-header *ngIf="settings.actions.canSelect" class="select-multiple-header">
    <ion-checkbox [legacy]="true" class="order-item-select-all" [(ngModel)]="allSelected"
      (ionChange)="changeAllSelection($any($event).detail.checked)"></ion-checkbox>
    <ion-label class="ion-checkbox-label"><span translate="actions.select_all"></span></ion-label>
  </ion-list-header>

  <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
  <ng-container *ngIf="activeOrders != null">

    <cdk-virtual-scroll-viewport [itemSize]="approxHeaderHeight" style="width:100%; overflow-x: hidden;"
      [maxBufferPx]="760" [minBufferPx]="760" orientation="vertical">

      <ng-container *cdkVirtualFor="let group of orderGroups | mobilusOrderBy:{ sortedPropertyName: 'visible', sortedPropertyType: SortedPropertyType.BOOLEAN, direction: SortDirection.ASC  };">

        <ion-item-divider class="item-ios-with-padding group-item-divider" [ngClass]="group.viewClass">
          <ion-checkbox [legacy]="true" class="order-item-select-group" [hidden]="!settings.actions.canSelect"
            [(ngModel)]="group.selected"
            (ionChange)="changeGroupSelection(group, $any($event).detail.checked)"></ion-checkbox>

          <ion-label>
            <ion-badge color="primary">{{ group.size }}</ion-badge>&nbsp;
            <span [translate]="group.name"></span>
          </ion-label>
          <ion-toggle [legacy]="true" slot="end" [(ngModel)]="group.visible" (ngModelChange)="toggleGroupView(group.name)"></ion-toggle>
        </ion-item-divider>

        <ng-container *ngIf="group.visible === true">
          <ion-item-sliding #slidingItem *ngFor="let orderItem of group.orders; let i = index">
            <ion-item class="item-no-padding ion-text-wrap item-inner item-native" 
              *ngIf="orderItem">
              <ion-checkbox [legacy]="true" class="order-item-select" *ngIf="settings.actions.canSelect"
                [(ngModel)]="orderItem.selected" (ionChange)="markItem(orderItem)" slot="start"></ion-checkbox>
              <mobilus-order-item class="order-item-fill" [orderView]="orderItem" [index]="i" [isforHistory]="true"
                (click)="selectItem($event, orderItem)"></mobilus-order-item>
            </ion-item>
            <ion-item-options side="end">
              <ion-item-option expand="full" class="sliding-item-button" color="{{ transition.event.style }}"
                [ngClass]="{'emphasized-transition-button': transition.event.emphasized}"
                *ngFor="let transition of orderItem?.transitions" [hidden]="transition.secondary"
                [disabled]="transitionDisabled(orderItem, transition)"
                (click)="transitionOrder(orderItem, transition, slidingItem)">
                <fa-icon [icon]="['fas', transition.event.icon]"></fa-icon>
                <span [ngClass]="{'text-bold': transition.event.emphasized}">{{ transition.event.labelKey | translate
                  }}</span>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </ng-container>

      </ng-container>

    </cdk-virtual-scroll-viewport>

  </ng-container>


  <ion-item-group *ngIf="(!activeOrders || activeOrders.length < 1)" class="italic text-muted ion-padding">
    <ion-item lines="none">
      <ion-label class="text-center">{{ 'messages.empty_list' | translate }}</ion-label>
    </ion-item>
  </ion-item-group>
  <!-- barre pour permettre de réafficher un groupe -->
  <ion-list>
    <ion-item-divider *ngFor="let hiddenGroup of orderGroups" [hidden]="!hiddenGroup.hidden"
      class="item-ios-with-padding group-item-divider" [ngClass]="hiddenGroup.viewClass">
      <ion-label>
        <ion-badge color="primary">{{ hiddenGroup.size }}</ion-badge>&nbsp;
        <span [translate]="hiddenGroup.name"></span>
      </ion-label>
      <ion-toggle [legacy]="true" *ngIf="!hiddenGroup.hasNoOrder" [(ngModel)]="hiddenGroup.visible"
        (ngModelChange)="toggleGroupView(hiddenGroup.name)"></ion-toggle>
    </ion-item-divider>
  </ion-list>
</ion-content>


<ion-footer class="actions-footer" [ngClass]="{'one-transition-footer':transitionsSelection.length === 1,
'one-type-multiple-transition-footer': transitionsSelection.length === 4,
'two-type-multiple-transition-footer': transitionsSelection.length === 6  }" *ngIf="transitionsSelection.length > 0">
  <ng-container *ngIf="hasOneSelected">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row *ngIf="transitionsSelection.length > 1">
        <ion-col size="6" *ngFor="let transition of transitionsSelection" class="transitions-buttons-col">
          <mobilus-transition-button [transition]="transition" [connected]="connectionStatus.isNetworkConnectedForUser"
            (onClick)="transitionSelection(transition, $event)"></mobilus-transition-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="transitionsSelection.length === 1">
        <ion-col size="12" *ngFor="let transition of transitionsSelection" class="transitions-buttons-col">
          <mobilus-transition-button [transition]="transition" [connected]="connectionStatus.isNetworkConnectedForUser"
            (onClick)="transitionSelection(transition, $event)"></mobilus-transition-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-footer>