import { Component, OnDestroy, OnInit, NgZone, ViewChild } from '@angular/core';
import { IonRefresher, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, merge, Subject } from 'rxjs';
import { filter, takeUntil} from 'rxjs/operators';

// MODELS
// import { AppError } from '@models/base/base';
import { Client } from '@models/business/client.model';
import { ConnectionStatus } from '@models/information/connection-status.model';
import { ObjectDatabaseChange } from '@models/pouchdb/object-database-change-model';
import { Order } from '@models/business/order.model';
import { TransitionRequest } from '@models/workflow/transition-request.model';

// COMPONENTS
import { TransitionDetailDebugComponent } from '@app/components/transition-detail-debug/transition-detail-debug.component';

// PROVIDERS
import { AwaitingTransitionStoreService } from '@services/awaiting-transition/awaiting-transition-store.service';
import { ConnectionStatusService } from '@services/connection-status-service/connection-status.service';
import { CommonUtils } from '@services/utils/common-utils';
import { DialogsService } from '@services/dialogs/dialogs';
import { Loading } from '@services/loading/loading';
import { LogService } from '@services/log/log.service';
import { OrderStoreService } from '@services/order-store/order-store.service';
import { TransitionService } from '@services/transition/transition.service';

@Component({
  templateUrl: './transitions-debug.component.html',
  styleUrls: ['./transitions-debug.component.scss'],
})
export class TransitionsDebugComponent implements OnDestroy, OnInit {

  public explanation: string = null;

  @ViewChild(IonRefresher, { static: false }) public refresher: IonRefresher;

  public connectionStatus: ConnectionStatus = new ConnectionStatus();
  public awaitingTransitionAndOrderList: Array<{
    transitionRequest: TransitionRequest,
    order: Order,
    isBeingProcessed: boolean
  }>;
  public errorShowReloadUI: boolean;

  private _manuallyRetryingTransition = false;
  public get manuallyRetryingTransition() {
    return this._manuallyRetryingTransition;
  }
  public set manuallyRetryingTransition(value) {
    this.ngZone.run(() => {
      this._manuallyRetryingTransition = value;
    });
  }

  public reInitRequired: Subject<void> = new Subject<void>();
  public componentDestroyed: Subject<void> = new Subject<void>();

  constructor(
    private awaitingTransitionStoreService: AwaitingTransitionStoreService,
    private commonUtils: CommonUtils,
    private connectionStatusService: ConnectionStatusService,
    private dialogsService: DialogsService,
    private loading: Loading,
    private log: LogService,
    private ngZone: NgZone,
    private modalController: ModalController,
    private orderStoreService: OrderStoreService,
    private translateService: TranslateService,
    public transitionService: TransitionService
  ) { }

  public async ngOnInit() {
    this.explanation = this.translateService.instant('awaitingTransition.explanation');

    this.connectionStatusService.connectionStatusSubscription
      .pipe(
        takeUntil(this.componentDestroyed),
      )
      .subscribe((connectionStatus) => {
        this.connectionStatus = connectionStatus;
      });
    await this.awaitingTransitionStoreService.ready();
    await this.init();
    this.awaitingTransitionStoreService
      .getLiveChanges()
      .pipe(
        takeUntil(merge(this.reInitRequired, this.componentDestroyed)),
        filter(pendingTransitionObjectDatabaseChange => pendingTransitionObjectDatabaseChange != null && pendingTransitionObjectDatabaseChange.doc != null),
      )
      .subscribe(async (_pendingTransitionObjectDatabaseChange: ObjectDatabaseChange<TransitionRequest>) => {
        // NOTE: reload the view
        await this.init();
      }
      );
  }

  public async init(surpressBlockingUIDuringExecution = false): Promise<void> {
    if (surpressBlockingUIDuringExecution !== true) {
      await this.loading.present();
    }
    await this.getAllPendingTransitions();
    if (surpressBlockingUIDuringExecution !== true) {
      await this.loading.dismiss();
    }
  }

  private async getAllPendingTransitions() {
    try {
      const awaitingTransitionAndOrderList: Array<{
        transitionRequest: TransitionRequest,
        order: Order,
        isBeingProcessed: boolean
      }> = [];
      const awaitingTransitions: Array<TransitionRequest> = await firstValueFrom(this.awaitingTransitionStoreService.getAllDocs());
      for (const awaitingTransition of awaitingTransitions) {
        let order: Order = null;
        try {
          order = await firstValueFrom(this.orderStoreService.get(awaitingTransition.orderID));
        } catch (error) {
          // NOTE: if we fail to get the order for this pending transition we just show an error dialog with the details
          const errorDialogHeader = this.translateService.instant('pages.transitions-debug.messages.failed-get-pending-transition-order-error-message');
          const errorDialogMessage = this.commonUtils.safeStringify(error);
          this.dialogsService.showErrorDialog(errorDialogMessage, errorDialogHeader);
        }
        awaitingTransitionAndOrderList.push({
          transitionRequest: awaitingTransition,
          order,
          isBeingProcessed: false
        });
      }
      this.awaitingTransitionAndOrderList = awaitingTransitionAndOrderList;
      this.errorShowReloadUI = false;
    } catch (error) {
      const errorDialogHeader = this.translateService.instant('pages.transitions-debug.messages.failed-get-pending-transitions-error-message');
      const errorDialogMessage = this.commonUtils.safeStringify(error);
      this.dialogsService.showErrorDialog(errorDialogMessage, errorDialogHeader);
      this.awaitingTransitionAndOrderList = null;
      this.errorShowReloadUI = true;
    }
  }

  public getOrderClientName(client: Client): string {
    if (client) {
      let firstAndLastName = '';
      if (client.firstName || client.lastName) {
        if (client.firstName) {
          firstAndLastName += client.firstName;
        }
        if (client.firstName && client.lastName) {
          firstAndLastName += ' ';
        }
        if (client.lastName) {
          firstAndLastName += client.lastName;
        }
      }
      return firstAndLastName;
    }
  }

  public async doRefresh() {
    this.reInitRequired.next();
    await this.init(true);
    await this.refresher.complete();
  }

  public async openTransitionDetailDebugView(item: {
    transitionRequest: TransitionRequest,
    order: Order
  }) {
    const transitionsDebugComponentModal = await this.modalController.create({
      component: TransitionDetailDebugComponent,
      componentProps: {
        awaitingTransitionAndOrder: item
      },
      backdropDismiss: false
    });
    await transitionsDebugComponentModal.present();
  }

  public retryTransition(item: {
    transitionRequest: TransitionRequest,
    order: Order
  }) {
    if (!this.manuallyRetryingTransition) {
      this.manuallyRetryingTransition = true;
      const transitionRequestToProcess: TransitionRequest = item.transitionRequest;
      this.transitionService.performTransitionOrder(transitionRequestToProcess, 'Manual pending transition trigger', true)
        .subscribe(
          async () => {
            const successMessage = this.translateService.instant('pages.transitions-debug.messages.manual-transition-succeeded-message');
            await this.dialogsService.showSuccessDialog(successMessage);
            this.manuallyRetryingTransition = false;
          },
          async (err: any) => {
            this.log.error('[TransitionsDebugComponent] retryTransition - manual transition request failed:', err);
            const appError = this.commonUtils.convertToAppError(err);
            if (appError && appError.handled === false) {
              const errorMessagePrefix = this.translateService.instant('pages.transitions-debug.messages.manual-transition-failed-error-message');
              const errorMessage = `${errorMessagePrefix}${appError.message ? ` ${appError.message}` : ''}`;
              this.dialogsService.showErrorDialog(errorMessage);
            }
            this.manuallyRetryingTransition = false;
          }
        );
    }
  }

  public close() {
    this.modalController.dismiss();
  }

  public ngOnDestroy(): void {
    this.reInitRequired.unsubscribe();
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

}
